<template>
  <div class="invitation-detail container">
      <div class="breadcrumb text-center ml-3 mr-3">
          <h1><span>Mine/VIP Date Opslag <img alt="img" src="./../assets/images/headingicon.png"></span></h1>
          <div class="breadcrumb-url">
              <a :href="baseURL+'user/owner.html'">Mit Sted</a>><span>Mine/VIP Date Opslag</span>
          </div>
      </div>
     
       <div class="my-post-bg  pt-2 pl-3 pb-3 pr-3 ml-0 mr-0 ml-sm-3 mr-sm-3">
        <div v-if="loading" class="loader">
            
        </div>
        <div v-else>
            <div class="row align-items-center">
                <div class="col-md-6 order-2 order-md-1 text-center text-md-left">
                    <ul class="nav nav-custom-tabs">
                        <li class="nav-item "><a data-toggle="tab" href="#created" v-on:click="tabClick('created')" :class="activeTab === 'created' ? 'active' : ''" class="nav-link">Oprettet af mig</a></li>
                        <li class="nav-item"><a data-toggle="tab" href="#received-applied" v-on:click="tabClick('received')" :class="activeTab === 'received' ? 'active' : ''" class="nav-link">Oprettet af andre</a></li>
                    </ul>
                </div>
                <div class="col-md-6 text-center text-md-right order-1 order-md-2 pb-3 pb-md-0">
                    <a :href="baseURL+'user/adddating.html'" class="btn btn-primary btn-sm">Opret ny Vip invitation/ Dateopslag</a>
                </div>
            </div>
            <div class="tab-content">
                
                <div id="created" v-bind:class="activeTab === 'created' ? 'in active show' : ''" class="tab-pane fade">
                    <div class="row align-items-center mt-4">
                        <div class="col-md-7 col-sm-6 pb-2 pb-sm-0 text-center text-sm-left">
                           
                            <div class="container text-select" id="people">
                                <strong>Filtrér: </strong>
                                <select class="filter" v-on:change="sortByData($event)" v-model="sortBy">
                                   <option value="create_date">Oprettet</option>
                                    <option value="end_date">Udløber</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-6 invitation-filter">
                            <ul class="nav justify-content-end">
                                <li class="nav-item "><a v-bind:class="type === 0 ?  'Active' : ''" class="nav-link" v-on:click="filterData(0)">Alle</a></li>
                                <li class="nav-item"><a v-bind:class="type === 2 ?  'Active' : ''" class="nav-link" v-on:click="filterData(2)">VIP</a></li>
                                <li class="nav-item"><a v-bind:class="type === 1 ?  'Active' : ''" class="nav-link" v-on:click="filterData(1)">Date Opslag</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- VIP invitation  -->
                    <div v-if="total_count != 0">
                        <div v-for="item in invitationList" :key="item.id" class="invitation-item border-radius bg-dark text-light p-3 mt-3">
                            <div v-if="item.uservip != null" class="d-block d-sm-flex">
                                <div class="text-center mb-3">
                                
                                    <a v-if="item.uservip != null" target="_blank"  :href="item.profile_link">
                                        <div :class="userMembership === '2' ? '' : 'img-blur'" class="img-wrap profile-lg">
                                            <img :src="baseURL+item.avatar" class="profile-lg" alt="profile">
                                        </div>
                                    </a>
                                    <img v-else src="./../assets/images/groupicon.png"  alt="profile">
                                    
                                </div>
                                <div class="w-100 pl-0 pl-sm-3 text-center text-sm-left">
                                    <div class="row mb-2">
                                        <div class="col-md-6 mb-2 mb-sm-0">
                                            <h5 class="mb-0">
                                                <a :href="item.profile_link" target="_blank"> 
                                                    {{item.name}}
                                                </a>
                                                <span class="profile-icon">
                                                    <a :class="getLargIcon(1)" v-tooltip="getLargIconTitle(1)" href=#></a>
                                                    <a :class="getSmallIcon(1)" v-tooltip="getLargIconTitle(1)" href=#></a>
                                                </span>
                                                <a v-if="userMembership === '2'" :href="item.chat_now" target="_blank" class="chat-btn ml-3"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                                                <a v-else data-toggle="modal" data-target="#plan-description"  class="chat-btn ml-3"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                    
                                            </h5>
                                        </div>
                                        <div class="mb-2 mb-md-0 col-md-6 d-flex flex-wrap align-items-center justify-content-center justify-content-md-end justify-content-sm-start">
                                            
                                            <span class="mr-0 mr-sm-3 w-100 w-sm-auto order-4 order-sm-1 mt-2 mt-sm-0">
                                                <div  class="text-center text-sm-right status">
                                                    <!-- <strong>Status:</strong>  -->
                                                
                                                    <span v-if="item.status === 'Reject' || item.status === 'Approved' || item.status === 'Pending' && item.remaining_seconds_to_expire != '0'">
                                                        <span v-if="item.status === 'Pending'" class="text-gold"> Afventer</span>
                                                        <span v-if="item.status === 'Reject'" class="text-danger"> Afvist</span>
                                                        <span v-if="item.status === 'Approved'" class="text-success"> Accepteret</span>
                                                    </span>
                                                    <span v-else-if="item.remaining_seconds_to_expire === '0'" class="text-danger"> Udløbet</span>
                                                </div>
                                            </span>

                                            <span class="black-info mr-3 order-1 order-sm-2">
                                                <span class="text-gold d-flex align-items-center"><img alt="img" class="pr-1" src="./../assets/images/star.png"> VIP</span>
                                            </span>
                                            <a :href="item.edit_link" v-if="item.status != 'Reject' && item.status != 'Approved' && item.remaining_seconds_to_expire != '0'" title="Edit" class="Edit mr-3 order-2 order-sm-3">
                                                <img alt="img" src="./../assets/images/editicon.png" class="">
                                            </a>
                                            <a href="javascript:void(0)" v-on:click="deleteInvitation(item.encription_id)" title="Delete" class="Delete order-3 order-sm-4">
                                                <img alt="img" src="./../assets/images/deleteicon.png" class="">
                                            </a>
                                        </div>

                                    </div>
                                    <div class="cursor-pointer" v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                        <p class="mb-0" v-if="item.uservip != null && item.user_id != ''">{{item.age}} år / {{item.height}} cm / {{item.weight}} kg / {{item.city}} {{item.code}}</p>
                                        <p class="mb-1 created-date" ><span>Oprettet: {{myDate(item.time) | customDate}}</span><span>Udløber: {{item.invitation_end_date }}</span></p>
                                        <h5 class="mb-1 item-title"><a>{{item.title}}</a></h5>
                                        <p class="mb-1" v-html="item.description.length > '180' ? item.description.substring(0, 180) + '... <a class=more-link>Se mere</a>' : item.description"></p>
                                    </div>
                                    <vue-countdown-timer v-if="item.remaining_seconds_to_expire != '0' && item.status === 'Pending'" :class="item.remaining_seconds_to_expire === '0' ? 'bg-danger'  :  ''" class="timer mt-3 mb-0 mb-sm-2"
                                        @end_callback="endCallBack('event ended')"
                                        :start-time="myDate(item.time) | formatTimer"
                                        :end-time="myDate(item.end_date) | formatTimer"
                                        :interval="1000"
                                        label-position="begin"
                                        :end-text="'Udløbet'"
                                        :day-txt="' dag(e)'"
                                        :hour-txt="' time(r)'"
                                        :minutes-txt="' minut(ter)'"
                                        :seconds-txt="' sekund(er)'">
                                        <template slot="countdown" slot-scope="scope">
                                            <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                            <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                            <label><span class="minuts">{{scope.props.minutes }}</span>{{scope.props.minutesTxt}}</label>
                                            <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                                        </template>
                                    </vue-countdown-timer>
                                </div>
                            </div>

                                <!-- public  -->
                            <div v-else class="d-flex pub">
                                <div class="text-center mb-3">
                                    <img src="./../assets/images/groupicon.png" class="profile-lg" alt="profile">
                                </div>
                                <div class="d-flex flex-wrap flex-inner w-100">
                                    <div class="order-2 order-md-2 col-md-12 w-sm-100 pl-0 pr-0 pl-sm-3 text-center text-sm-left">
                                        <div class="row align-items-center mt-1 mb-2">
                                            <div class="col-md-5 order-2 order-md-1">
                                                <p v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))' class="mb-1 mb-md-0 created-date cursor-pointer"><span>Oprettet: {{myDate(item.time) | customDate}}</span><span>Udløber: {{item.invitation_end_date }}</span></p>
                                            </div>
                                            <div class="col-md-7 order-1 order-md-2">
                                                <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-end justify-content-sm-start">
                                                    <span v-if="item.remaining_seconds_to_expire === '0'" class="status text-center text-sm-right mr-0 mr-sm-3 mt-2 mt-sm-0 order-5 w-xs-100 order-sm-1">
                                                        <!-- <strong>Status:</strong>  -->
                                                        <span class="text-danger"> Udløbet</span>
                                                    </span>
                                                    <div class="mr-3 order-1 order-sm-2">
                                                        <span class="black-info ">
                                                            <span class="align-items-center"> Date Opslag</span>
                                                        </span>
                                                    </div>
                                                    <a v-if="!item.intrestedUsers.data && item.remaining_seconds_to_expire != '0'" :href="item.edit_link" title="Edit" class="Edit mr-3 order-2 order-sm-3">
                                                        <img alt="img" src="./../assets/images/editicon.png" class="">
                                                    </a>
                                                    <a href="javascript:void(0)" v-on:click="deleteInvitation(item.encription_id)" title="Delete" class="Delete order-3 order-sm-4">
                                                        <img alt="img" src="./../assets/images/deleteicon.png" class="">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cursor-pointer" v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                            <h5 class="mb-1 item-title"><a>{{item.title}}</a></h5>
                                            <p class="mb-1" v-html="item.description.length > '180' ? item.description.substring(0, 180) + '... <a class=more-link>Se mere</a>' : item.description"></p>
                                        </div>
                                        <div class="row align-items-center">
                                            <div class="col-lg-7">
                                                <vue-countdown-timer v-if="item.remaining_seconds_to_expire != '0' " :class="item.remaining_seconds_to_expire === '0' ? 'bg-danger'  :  ''" class="timer mb-0 mb-sm-2"
                                                    @end_callback="endCallBack('event ended')"
                                                    :start-time="myDate(item.time) | formatTimer"
                                                    :end-time="myDate(item.end_date) | formatTimer"
                                                    :interval="1000"
                                                    label-position="begin"
                                                    :end-text="'Udløbet'"
                                                    :day-txt="' dag(e)'"
                                                    :hour-txt="' time(r)'"
                                                    :minutes-txt="' minut(ter)'"
                                                    :seconds-txt="' sekund(er)'">
                                                    <template slot="countdown" slot-scope="scope">
                                                        <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                                        <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                                        <label><span class="minuts">{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}</label>
                                                        <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                                                    </template>
                                                </vue-countdown-timer>
                                            </div>
                                            <div v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))' class="col-lg-5 cursor-pointer">
                                                 <div v-if="item.intrestedUsers.data" class="interested-list pr-3 mt-3 mt-lg-2 text-center text-lg-right text-sm-left">
                                                    <p class="text-center text-sm-left text-lg-right">Vist interesse</p>
                                                    <span v-for="(int , i) in item.intrestedUsers.data.slice(0, 5)" :key="'profile-'+i" :class="i === 4 ? 'last' : ''" class="profile-list">
                                                        <span v-if="i >= 4" :class="userMembership === '2' ? '' : 'img-blur'" class="itm-count img-wrap profile-md">
                                                            <a v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                                            {{item.intrestedUsers.data.length > 5 ? '+' : '' }}{{item.intrestedUsers.data.length > 5 ? item.intrestedUsers.data.length-5 : '' }}
                                                            </a>
                                                        </span>
                                                        <span :class="userMembership === '2' ? 'img-wrap profile-md' : 'img-wrap img-blur profile-md'">
                                                            <a v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                                                <img :src="int.avatar" class="profile-md" alt="profile">
                                                            </a>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <div v-else class="border-radius bg-dark p-3 mt-3">
                        <p class="no-data text-center mb-0">Du har ingen aktive invitationer</p>
                    </div>
                    <div v-if="total_count >= 24" :class="shoBtn === true ? 'mt-4' : ''" class="col-12 text-center ">
                        <button v-if="shoBtn === true" class="btn btn-primary" v-on:click="richEnd()">Se mere</button>
                    </div>

                </div>
                <div id="received-applied" v-bind:class="activeTab === 'received' ? 'in active show' : ''" class="tab-pane fade">
                    <div class="row align-items-center mt-4">
                        <div class="col-md-7 col-sm-6 pb-2 pb-sm-0 text-center text-sm-left">
                            <div class="container text-select" id="people">
                                <strong>Filtrér: </strong>
                                <select class="filter" v-on:change="sortByData2($event)"  v-model="sortBy2">
                                    <option value="create_date">Oprettet</option>
                                    <option value="end_date">Udløber</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-6 invitation-filter">
                            <ul class="nav justify-content-end">
                                <li class="nav-item "><a v-bind:class="type2 === 0 ?  'Active' : ''" class="nav-link" v-on:click="filterData2(0)">Alle</a></li>
                                <li class="nav-item"><a v-bind:class="type2 === 2 ?  'Active' : ''" class="nav-link" v-on:click="filterData2(2)">VIP</a></li>
                                <li class="nav-item"><a v-bind:class="type2 === 1 ?  'Active' : ''" class="nav-link" v-on:click="filterData2(1)">Date Opslag</a></li>
                            </ul>
                        </div>
                    </div>

                    <!-- received invitation  -->
                    <div v-if="total_count2 != 0">
                        <div v-for="item in invitationReceivedList" :key="'received'+item.id" class="invitation-item border-radius bg-dark text-light p-3 mt-3">
                            <div class="d-block d-sm-flex">
                                <div class="text-center mb-3">
                                    <a :href="item.profile_link" target="_blank">
                                        <div :class="userMembership === '2' ? '' : 'img-blur'" class="img-wrap profile-lg">
                                            <img :src="baseURL+item.avatar" class="profile-lg" alt="profile">
                                        </div>
                                    </a>

                                </div>
                                <div class="w-100 pl-0 pl-sm-3 text-center text-sm-left">
                                    <div class="row">
                                        <div class="col-md-6 mb-2 mb-sm-0">
                                            <h5 >
                                                <a :href="item.profile_link" target="_blank"> 
                                                    {{item.name}}
                                                </a>
                                                <span class="profile-icon">
                                                    <a :class="getLargIcon(1)" v-tooltip="getLargIconTitle(1)" href=#></a>
                                                    <a :class="getSmallIcon(1)" v-tooltip="getLargIconTitle(1)" href=#></a>
                                                </span>
                                                <a v-if="userMembership === '2'" :href="item.chat_now" target="_blank" class="chat-btn ml-3"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                                                <a v-else data-toggle="modal" data-target="#plan-description"  class="chat-btn ml-3"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                    
                                            </h5>
                                        </div>
                                        <div class="mb-2 mb-md-0 col-md-6 d-flex flex-wrap align-items-center justify-content-center justify-content-md-end justify-content-sm-start">
                                            <span class="mr-0 mr-sm-3 w-100 w-sm-auto order-4 order-sm-1 mt-2 mt-sm-0">
                                                <div v-if="item.status != 'Pending' && item.status != 'Shown Interest'" class="text-center text-sm-right status">
                                                    <!-- <strong>Status:</strong>  -->
                                                
                                                    <span v-if="item.status === 'Reject' || item.status === 'Approved' || item.status === 'Pending'">
                                                        <!-- <span v-if="item.status === 'Pending'" class="text-gold"> Afventer</span> -->
                                                        <span v-if="item.status === 'Reject'" class="text-danger"> Afvist</span>
                                                        <span v-if="item.status === 'Approved'" class="text-success"> Accepteret</span>
                                                    </span>
                                                    <span v-else-if="item.remaining_seconds_to_expire === '0'" class="text-danger"> Udløbet</span>
                                                </div>
                                                <div class="status" v-else-if="item.status === 'Pending' && item.remaining_seconds_to_expire === '0'">
                                                    <!-- <strong>Status:</strong>  -->
                                                    <span class="text-danger"> Udløbet</span>
                                                </div>
                                                <div class="status" v-else-if="item.status === 'Pending' && item.invitation_type === 'Creator'">
                                                    <!-- <strong>Status:</strong>  -->
                                                    <span class="text-gold"> Afventer</span>
                                                </div>
                                                <div class="status" v-else-if="item.status === 'Shown Interest' && item.invitation_type === 'Receiver'">
                                                    <!-- <strong>Status:</strong> -->
                                                    <span v-if="item.remaining_seconds_to_expire === '0'" class="text-danger"> Udløbet</span>
                                                    <span v-else class="text-success"> Vist interesse</span>
                                                    
                                                </div>
                                            </span>

                                            <span class="black-info order-1 order-sm-2">
                                                <span v-if="item.uservip != null" class="text-gold d-flex align-items-center"><img alt="img" class="pr-1" src="./../assets/images/star.png"> VIP</span>
                                                <span v-else>Date Opslag</span>
                                            </span>
                                            
                                        </div>

                                    </div>
                                    <div class="cursor-pointer" v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                        <p class="mb-0" v-if="item.uservip != null && item.user_id != ''">{{item.age}} år / {{item.height}} cm / {{item.weight}} kg / {{item.city}} {{item.code}}</p>
                                        <p class="mb-1 created-date"><span>Oprettet: {{myDate(item.time) | customDate}}</span><span>Udløber: {{item.invitation_end_date }}</span></p>
                                        <h5 class="mb-1 item-title"><a>{{item.title}}</a></h5>
                                        <p class="mb-1" v-html="item.description.length > '180' ? item.description.substring(0, 180) + '... <a class=more-link >Se mere</a>' : item.description"></p>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-6 cursor-pointer" v-on:click='openDetail(item.encription_id, item.user_id, getType(item.uservip))'>
                                            <vue-countdown-timer v-if="item.remaining_seconds_to_expire != '0' && item.status === 'Pending'" :class="item.remaining_seconds_to_expire === '0' ? 'bg-danger'  :  ''" class="timer mt-3 mb-0 mb-sm-2"
                                                @end_callback="endCallBack('event ended')"
                                                :start-time="myDate(item.time) | formatTimer"
                                                :end-time="myDate(item.end_date) | formatTimer"
                                                :interval="1000"
                                                label-position="begin"
                                                :end-text="'Udløbet'"
                                                :day-txt="' dag(e)'"
                                                :hour-txt="' time(r)'"
                                                :minutes-txt="' minut(ter)'"
                                                :seconds-txt="' sekund(er)'">
                                                <template slot="countdown" slot-scope="scope">
                                                    <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                                    <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                                    <label><span class="minuts">{{scope.props.minutes }}</span>{{scope.props.minutesTxt}}</label>
                                                    <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                                                </template>
                                            </vue-countdown-timer>
                                            <vue-countdown-timer v-if="item.remaining_seconds_to_expire != '0' && item.status === 'Shown Interest'" :class="item.remaining_seconds_to_expire === '0' ? 'bg-danger'  :  ''" class="timer mt-3 mb-0 mb-sm-2"
                                                @end_callback="endCallBack('event ended')"
                                                :start-time="myDate(item.time) | formatTimer"
                                                :end-time="myDate(item.end_date) | formatTimer"
                                                :interval="1000"
                                                label-position="begin"
                                                :end-text="'Udløbet'"
                                                :day-txt="' dag(e)'"
                                                :hour-txt="' time(r)'"
                                                :minutes-txt="' minut(ter)'"
                                                :seconds-txt="' sekund(er)'">
                                                <template slot="countdown" slot-scope="scope">
                                                    <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                                    <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                                    <label><span class="minuts">{{scope.props.minutes }}</span>{{scope.props.minutesTxt}}</label>
                                                    <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                                                </template>
                                            </vue-countdown-timer>
                                        </div>
                                        <div class="text-center text-sm-left text-md-right mb-2 mb-md-0 col-md-6">
                                            
                                            <div v-if="userMembership === '2'">
                                               <div v-if="item.uservip != null && item.remaining_seconds_to_expire != '0' && item.status === 'Pending'" class=" action-btn pt-3 mb-1 mb-md-3">
                                                    <button v-on:click="takeAction(item.encription_id, item.user_id, 1)" class="btn btn-primary btn-long mr-1">Acceptér</button>
                                                    <button v-on:click="takeAction(item.encription_id, item.user_id, 2)" class="btn btn-primary btn-long ml-1">Afvis</button>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="item.uservip != null && item.remaining_seconds_to_expire != '0' && item.status === 'Pending'" class=" action-btn pt-3 mb-1 mb-md-3">
                                                    <button data-toggle="modal" data-target="#plan-description"
                                                    class="btn btn-primary btn-long mr-1">Acceptér</button>
                                                    <button data-toggle="modal" data-target="#plan-description"
                                                    class="btn btn-primary btn-long ml-1">Afvis</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div v-else class="border-radius bg-dark p-3 mt-3">
                        <p class="no-data text-center mb-0">Du har ingen aktive invitationer</p>
                    </div>
                    <div v-if="total_count2 >= 24" :class="shoBtn2 === true ? 'mt-4' : ''" class="col-12 text-center ">
                        <button v-if="shoBtn2 === true" class="btn btn-primary" v-on:click="richEnd2()">Se mere</button>
                    </div>
                    

                    

                </div>
            </div>
        </div>
     
      </div>
      <div class="row m-0 mb-2">
        <div v-for="item in bannerImages" :key="item.id" :id="'image-'+item.id" class="col-md-2 col-sm-4 col-4 pl-0 pr-0">
            <div class="below-slider-image">
                <a :href="item.link_path">
                    <img :src="baseURL+item.image" alt="Velkommen" class="img-fluid">
                </a>
            </div>
        </div>     
       </div>

<!-- Modal -->
<div :class="showModal ? 'show' : ''" class="modal fade"  id="deleteConfirm" tabindex="-1" aria-hidden="true">
  <div class="modal-shadow" @click="showModal = false"></div>
  <div class="modal-md modal-dialog modal-dialog-centered">
    <div class="modal-content dark-popup">
        <div class="modal-content-inner">
            <div class="modal-header blank-header">
                <h4 class="modal-title text-center" id="deleteConfirmLabel">Vil du slette denne invitation ?</h4>
                <button type="button" class="close" @click="showModal = false">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-footer pt-4">
               <button type="button" @click="deleted()" class="btn btn-primary btn-sm btn-long">Ja</button>
                <button type="button" class="btn btn-dark btn-sm btn-long" @click="showModal = false" data-dismiss="modal">Nej</button>
                
            </div>
        </div>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment'
import EventBus from './../bus';
import axios from 'axios';

Vue.prototype.moment = moment

export default {
  name: 'InvitationDetails.vue',
  components: {
    
  },
  data(){
    return{
      baseURL: process.env.VUE_APP_BASE_URL,
      logedinUser:'',
      userMembership: '',
      loading: true,
      shoBtn: false,
      shoBtn2: false,
      invitationList: [],
      invitationReceivedList: [],
      bannerImages: [],
      el:  "#people",
      type: 0,
      type2: 0,
      sortBy: 'create_date',
      sortBy2: 'create_date',
      loadData: '1',
      loadData2: '1',
      total_count: '',
      total_count2: '',
      activeTab: 'created',
      DeleteUserID: '',
      showModal: false,
      deleteInv:false,
      accessToken: '',
    }
  },
  beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
  mounted() { 
    EventBus.$on('logedinUser', this.getLogedinUser);
    EventBus.$on('userMembership', this.getMembership);
    this.activeTab = this.$route.query.type;
    
  },
  methods:{
     
    getLogedinUser(value) {
        this.logedinUser = value;
        this.getSentInvitations();
        this.getreceivedInvitations();
   },
   tabClick(value){
      
    if(this.$route.query.type != value){
        this.loading = true;
       if(value === 'created'){
            this.activeTab = 'created';
            this.$router.push({ name: 'InvitationListing', query: { type: 'created' } })
        }else{
            this.activeTab = 'received';
            this.$router.push({ name: 'InvitationListing', query: { type: 'received' } })
        }
      }
   },
   richEnd() {
        var i = this.loadData;
        
        if(i-1 < this.pageCount){
            this.loadData = ++i;
            this.shoBtn = true;
            this.getSentInvitations(this.loadData);
            
        }  
    },
    richEnd2() {
        var i = this.loadData2;
        this.activeTab = 'received';
        if(i-1 < this.pageCount2){
            this.loadData2 = ++i;
            this.shoBtn2 = true;
            this.getreceivedInvitations(this.loadData2);
            
        }  
    },
    deleteInvitation: function(id){
        this.showModal = true;
        this.DeleteUserID = id;
    },
    deleted: function(){
        this.deleteInv = true;
        if(this.deleteInv === true){  
            let deleteAction = new FormData();
            deleteAction.append('id', this.DeleteUserID);
                
            axios.post('deleteInvitation', deleteAction).then(response => {
                    const data = response.data;
                if(data.message === 'Success'){
                    this.$toasted.show("Denne invitation er slettet", {
                        type : 'error',
                        position: 'top-center',
                        theme: 'toasted-primary'
                    })                    
                    this.showModal = false;
                    this.loading = true;
                    this.invitationList = [];
                    this.invitationReceivedList = [];
                    this.getSentInvitations();
                    this.getreceivedInvitations()
                }

             }, (error) => {
                    console.log('error: ',error);
            });  
        }      
    },
    myDate: function(val){
        let timestamp = { seconds: val, nanoseconds: 0 }     
        return new Date(timestamp.seconds * 1000)
    },
    
   getType(type){
       if(type != null){
           var getType = 1;
       }else{
           getType = 2;
       }
       return getType
   },
   takeAction: function(id, dating_id, type){
        let profileAction = new FormData();
        profileAction.append('id', id);
        profileAction.append('datingId', dating_id);
        profileAction.append('userId', this.accessToken);
        profileAction.append('type', type);
            
        axios.post('acceptOrRejectInvitation', profileAction).then(response => {
            const data = response.data;
            if(data.message === 'Success'){
                if(type === 1){
                    
                    this.$toasted.show("Invitation accepteret", {
                        type : 'success',
                        position: 'top-center',
                        theme: 'toasted-primary'
                    })
                }else{
                    this.$toasted.show("Invitation afvist", {
                        type : 'error',
                        position: 'top-center',
                        theme: 'toasted-primary'
                    })
                }
               
                this.loading = true;
                this.invitationList = [];
                this.invitationReceivedList = [];
                this.getSentInvitations();
                this.getreceivedInvitations()
            }

         }, (error) => {
                    console.log('error: ',error);
        });
    },
   endCallBack: function() {
       this.loading = true;
         
        this.invitationList = [];
        this.getSentInvitations() 
        this.invitationReceivedList = [];
         this.getreceivedInvitations()  
    },
   getMembership(value) {
      this.userMembership = value;
   },
   getSentInvitations: function(){  
 
    let formData = new FormData();
    
    formData.append('userId', this.accessToken);
    formData.append('type', this.type);
    formData.append('pageNo', this.loadData);
    formData.append('sortBy', this.sortBy);
    
    axios.post('getSentInvitations', formData).then(response => {
        const data = response.data;
        this.invitationList =  this.invitationList.concat(data.result.data);
            
        this.bannerImages = data.bannerImages.bannerImages; 
        this.pageCount = data.page_counts;
        this.total_count = data.total_count;
        this.loading = false;
        
        if(this.pageCount === this.loadData){
            this.shoBtn = false;
        }else{
            if(this.pageCount > 0 && this.loadData < this.pageCount + 1){
                this.shoBtn = true;
            }else{
                this.shoBtn = false;
            }
        }
        
         }, (error) => {
                    console.log('error: ',error);
        }).finally(() => (this.loading = false));
    },
    getreceivedInvitations: function(){  
     
    let formData = new FormData();
    
    formData.append('userId', this.accessToken);
    formData.append('type', this.type2);
    formData.append('pageNo', this.loadData2);
    formData.append('sortBy', this.sortBy2);
    
    axios.post('getRecievedInvitations', formData).then(response => {
        const data = response.data;
        this.invitationReceivedList = this.invitationReceivedList.concat(data.result.data);
         this.pageCount2 = data.page_counts;
        this.total_count2 = data.total_count;
        this.loading2 = false;
        
        if(this.pageCount2 === this.loadData2){
            this.shoBtn2 = false;
        }else{
            if(this.pageCount2 > 0 && this.loadData2 < this.pageCount2 + 1){
                this.shoBtn2 = true;
            }else{
                this.shoBtn2 = false;
            }
        }
        
         }, (error) => {
                    console.log('error: ',error);
        }).finally(() => (this.loading = false));
    },
    openDetail: function(user_id, dating_id,  type){
         if(type === 2){
            this.$router.push({ name: 'Invitation-details', query: { id: user_id,type: type } })
        
        }else{
            this.$router.push({ name: 'Invitation-details', query: { id: user_id,datingId: dating_id, type: type } })
        
        }
        window.location.reload()
        
    },

    filterData: function(key){
        this.loadData = '1';
        this.type = key; 
        this.loading = true;  
        this.invitationList = [];
        this.getSentInvitations()       
    },
    filterData2: function(key){
        this.loadData2 = '1';
        this.type2 = key; 
        this.loading = true; 
        this.invitationReceivedList = [];
         this.getreceivedInvitations()   
    },
    sortByData : function(event){
        this.sortBy = event.target.value; 
        this.loading = true;  
        this.invitationList = [];
        this.getSentInvitations()    
    },
    sortByData2 : function(event){
        this.sortBy2 = event.target.value; 
        this.loading = true; 
        this.invitationReceivedList = [];
        this.getreceivedInvitations()
    },
   getLargIcon: function(id){
        if(id==1){
            var largeIcon='large_icon-sugar-baby-male'; 
        }else if(id == 2){
            largeIcon='large_icon-sugar-baby-female';              
        }else if(id==3){
            largeIcon='large_icon-sugar-dad';
        }else if(id==4){
            largeIcon='large_icon-sugar-mom';              
        }else if(id==5){
            largeIcon='large_icon-toy-boys-mand';              
        }
        return largeIcon;  
    },

    getLargIconTitle: function(id){
        if(id==1){
            var largeIcontitle='Sugar Baby (M)'; 
        }else if(id == 2){
            largeIcontitle='Sugar Baby (F)';              
        }else if(id==3){
            largeIcontitle='Sugar Dad';
        }else if(id==4){
            largeIcontitle='Cougar';              
        }else if(id==5){
            largeIcontitle='ToyBoy';              
        }
        return largeIcontitle;  
    },
    getSmallIcon: function(id){
        if(id==1){
            var largeIcon='small_icon-sugar-baby-male'; 
        }else if(id == 2){
            largeIcon='small_icon-sugar-baby-female';              
        }else if(id==3){
            largeIcon='small_icon-sugar-dad';
        }else if(id==4){
            largeIcon='small_icon-sugar-mom';              
        }else if(id==5){
            largeIcon='small_icon-toy-boys-mand';              
        }
        return largeIcon;  
    },
    yourCallBackFunction () {
         window.location.reload()
    },
  },
  watch:{
        $route (){
        
            this.loading = true
            this.loadData = '1';
            this.invitationList = [];
            this.invitationReceivedList = [];
            this.getSentInvitations()   
            this.getreceivedInvitations()  
        },
    },
     beforeRouteLeave(to, from, next) {
         this.yourCallBackFunction();
        return next(); 
    }
}
</script>