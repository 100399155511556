<template>
    <div class="landing-container">
        <!-- <div class="home-video">
            <video autoplay muted loop playsinline autobuffer>
                <source src="./../assets/video/login_page.mp4" type="video/mp4">
            </video>
        </div> -->
        <div v-if="authenticate" :class="'login-box form_'+activeForm">
            <!-- signup form  -->
            <div :class="activeForm === 'Signup' ? 'activeForm' : ''" id="signup">
                <div>
                    <div v-if="activeForm === 'Signup'">
                        <div>
                            <h3 class="text-center pb-2">Opret Profil</h3>
                            <p class="text-center text-light">Få Guldnøgle eller gratis Gæstekort hér</p>
                        </div>
                        <div class="signup" >
                            <form class="form-horizontal" id="signUpForm" :action="baseURL+'user/register.html'" method="post">
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <input type="text"
                                        v-model="registerField.name"
                                        name="name" class="form-control text-field-home" placeholder="Vælg dit profil navn*" id="name">
                                        <div class="text-danger error-msg" v-if="registerErrors.name">{{registerErrors.name}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <input type="email"
                                        v-model="registerField.email"
                                        name="email" class="form-control" placeholder="E-mail*" id="email" >
                                        <div class="text-danger error-msg" v-if="registerErrors.email">{{registerErrors.email}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pr-sm-1">
                                        <!-- <input type="number" min="18" max="100"
                                        v-model="registerField.age"
                                        name="age" class="form-control" placeholder="Alder*" id="age"> -->
                                        <datepicker format="dd-MM-yyyy" input-class="form-control" :open-date="selected" :disabledDates="disabledDates" v-model="registerField.age" placeholder="Alder*" name="age"></datepicker>
                                        <div class="text-danger error-msg" v-if="registerErrors.age">{{registerErrors.age}}</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pl-sm-1 pt-3 pt-sm-0">
                                        <select class="form-control" v-model="registerField.gender" name="gender" id="gender">
                                            <!-- <option selected disabled>Mand/ Kvinde*</option> -->
                                            <option value="1">Mand</option>
                                            <option value="0">Kvinde</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <input type="password"
                                        v-model="registerField.password"
                                        name="password" class="form-control" placeholder="Vælg adgangskode*"  id="password" >
                                        <div class="text-danger error-msg" v-if="registerErrors.password">{{registerErrors.password}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <input type="password"
                                        v-model="registerField.confirmPassword"
                                        name="confirm_password" class="form-control text-field-home" placeholder="Gentag adgangskode*"  id="confirm_password" equalto="#password">
                                        <div class="text-danger error-msg" v-if="registerErrors.confirmPassword">{{registerErrors.confirmPassword}}</div>
                                    </div>
                                    <div class="col-lg-12 text-danger error-msg" v-if="passwordError">{{passwordError}}</div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12 text-center">
                                        <button v-on:click="createValidateForm" v-if="!createFormValid" type="button" class="btn btn-primary w-100" >OPRET</button>
                                        <button v-on:click="createValidateForm" v-if="createFormValid" type="submit" class="btn btn-primary w-100" >OPRET</button>
                                    </div>
                                </div>
                                <div class="form-group row  pt-2">
                                    <div class="col-lg-12 text-center">
                                        <p class="mb-0 small-text">Har du allerede en profil ?</p>
                                        <a href="javascript:void(0);" class="" v-on:click="loginForm"><u>Login</u></a> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- login form  -->
            <div :class="activeForm === 'Login' ? 'activeForm' : ''" id="login">
                <div>
                    <div v-if="activeForm === 'Login'">
                        <div>
                            <h3 class="text-center pb-2">Kom ind hér</h3>
                            <p class="text-center text-light">Eksklusiv adgang kun for medlemmer.<br>Kræver Guldnøgle eller Gæstekort.</p>
                        </div>
                        
                        <div class="login_box pt-2">
                            <form id="loginForm">
                                
                                <div class="form-group">
                                    <input type="email" v-model="loginField.email"
                                    name="email" class="form-control text-field-home" placeholder="Email" id="emaillogins">
                                    <div class="form-control-feedback" v-if="attemptSubmit && missingEmail">This field is .</div>
                                    <div class="text-danger error-msg" v-if="errors.email">{{errors.email}}</div>
                                </div>
                                <div class="form-group mb-1">
                                    <input type="password"
                                    v-model="loginField.password" name="password" class="form-control text-field-home" placeholder="Adgangskode" id="passwordlogins">
                                    <div class="form-control-feedback" v-if="attemptSubmit">Make sure this is a number between 1 and 10.</div>
                                    <div class="text-danger error-msg" v-if="errors.password">{{errors.password}}</div>
                                </div>

                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" v-on:click="forgotForm"><u>Glemt adgangskode ?</u></a>
                                </div>
                                <div class="form-group">
                                    <vue-recaptcha ref="recaptcha" 
                                    sitekey="6LdcnLYUAAAAAHaWTEtgaHBCJgLZHkQJVlMv32-A"
                                    @verify="verifyMethod"
                                     />
                                </div>
                                <p class="text-danger error-msg" v-if="loginError">{{loginError}}</p>
                                <div class="form-group text-center">
                                    <button v-on:click="validateForm" type="button" :disabled="btnDisabled || submitted" class="btn btn-primary w-100" >LOGIN</button>
                                </div>
                                
                                <div class="form-group text-center  pt-2">
                                    <p class="mb-0 small-text">Ingen nøgle ?</p>
                                    <a href="javascript:void(0);" v-on:click="registerForm"><u>Få Guldnøgle eller gratis Gæstekort hér</u></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- reset password form  -->
            <div :class="activeForm === 'Reset' ? 'activeForm' : ''" id="forgot">
                <div>
                    <div v-if="activeForm === 'Reset'">
                        <div>
                            <h3 class="text-center pb-2">Har du glemt din adgangskode ?</h3>
                            <p class="text-center text-light">Skriv din e - mail hér, og tryk send.</p>
                            <p class="text-center text-light">Passer e - mailen med din profil, modtager du et link til ny adgangskode.</p>
                        </div>
                        <div class="login_box pt-2">
                            <form id="resetForm">
                                <div class="form-group">
                                    <input type="email"
                                    v-model="resetEmail" name="email" class="form-control text-field-home" placeholder="Email"  id="emailforgot">
                                    <div :class="resetSuccess ? 'text-success ' : ' text-danger ' +' error-msg'" v-if="resetError">{{resetError}}</div>
                                    
                                </div>
                                
                                <div class="form-group text-center">
                                    <button type="button" class="btn btn-primary w-100" v-on:click="resetValidateForm" :disabled="submitted" name="return">SEND</button>
                                </div>
                                
                                <div class="form-group text-center pt-2">
                                    <a href="javascript:void(0);" v-on:click="loginForm"><u>Tilbage til Log in ?</u></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <p class="text-center mt-3 login-copyright">Copyright © 2022. All Rights Reserved.</p>
        </div>
        <div v-else class="loader"></div>
        
        <!-- Modal -->
        <div class="flood-popup show" v-if="showAgeModal" id="ageConfirm" tabindex="-1" aria-hidden="true">
            <div class="modal-shadow"></div>
            <div class="modal-medium modal-dialog modal-dialog-centered">
                <div class="modal-content dark-popup">
                    <div class="modal-content-inner">
                        <div class="modal-body text-center">
                            <h4 class="modal-title mb-3 text-center">En vigtig besked</h4>
                            <p class="mb-0">For at fortsætte til Sugardating.dk®, skal du bekræfte din alder.<br>Er du min. 18 år ?</p>
                            <br>
                            <p class="mb-1">Admin, Sugardating.dk®</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="showAgeModal = false" class="btn btn-primary btn-sm btn-long">Ja</button>
                             <button type="button" class="btn btn-dark btn-sm btn-long" @click="confirmAge()">
                            Nej
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="flood-popup show" v-if="Adgang" id="ageUnderEighteen" tabindex="-1" aria-hidden="true">
            <div class="modal-shadow"></div>
            <div class="modal-small modal-dialog modal-dialog-centered">
                <div class="modal-content dark-popup">
                    <div class="modal-content-inner">
                        <div class="modal-body text-center pb-4 pt-5">
                            <h2 class=" mb-4 text-center"><strong>Adgang nægtet</strong></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {Messages} from './../const/index.js';
import Datepicker from 'vuejs-datepicker';

var eighteenYearsAgo = new Date();
var yearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-18);
yearsAgo = yearsAgo.setFullYear(yearsAgo.getFullYear()-21);

export default {
    name: 'Login',
    components:{
        VueRecaptcha,
       Datepicker,
    },
    data(){
        return{
            baseURL: process.env.VUE_APP_BASE_URL,
            disabledDates: {
                //to: new Date(),
                from: new Date(eighteenYearsAgo)
            },
            selected: new Date(yearsAgo),
            submitted: false,
            btnDisabled: true,
            loginField : {
                email: "",
                password: ""
            },
            registerField: {
                name: '',
                email: '',
                age: '',
                // age: '',
                gender: '1',
                password: '',
                confirmPassword: ''
            },
            registerErrors: {
                name: '',
                email: '',
                age: '',
                gender: '1',
                password: '',
                confirmPassword: ''
            },
            resetEmail: '',
            messages: Messages,
            passwordError : '',
            attemptSubmit: false,
            resetSuccess: false,
            errors: {
                email: "",
                password: ""
            },
            resetError: '',
            loginError: '',
            createFormValid: false,
            formValid : false,
            userId: '',
            activeForm : 'Login',
            authenticate: false,
            showAgeModal: false,
            Adgang: false
        }
    },
    mounted(){
        this.authenticate = true;
        this.showAgeModal = true;
    },
    methods: {

        confirmAge: function(){
            this.Adgang = true;
            this.showAgeModal = false;
             setTimeout(() => { window.location.href = 'http://google.com/'  }, 1000)
            
        },

        // this method is created for email verification
       validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        // this method is created for password verification
        validPassword: function (password) {
           var re = /.{6,}/
            return re.test(password); 
        },

        // this method is created for confirm password varification
        validConfirmPassword: function (confirmPassword) {
           var re = /.{6,}/
            return re.test(confirmPassword); 
        },

        // this method is created for name varification
        validName: function (name) {
           var re = /^[A-Za-z\s]+$/
            return re.test(name); 
        },

        // this method is created for validate reset password form field
        resetValidateForm: function(){
            this.resetError =  "";
            
            this.resetFormValid = true;

            if (!this.resetEmail) {
                this.resetError =  this.messages.blankEmail;
                this.resetFormValid = false;
            }

            if(this.resetEmail && !this.validEmail(this.resetEmail)){
                this.resetError =  this.messages.wrongEmail;
                this.resetFormValid = false;
            }

            if(this.resetFormValid){
                this.resetSubmit();
                this.resetFormValid = false;
            }
        },

        // this method is created for validate login form field
        validateForm: function () {
            this.errors = {
                email: "",
                password: ""
            }
            
            this.loginError = ""
            this.formValid = true; 

            if (!this.loginField.email) {
                this.$set(this.errors, 'email', this.messages.blankEmail)
                this.formValid = false;
            }
            if ( !this.loginField.password) {
                this.$set(this.errors, 'password', this.messages.blankPassword)
                this.formValid = false;
            }

            if(this.loginField.email && !this.validEmail(this.loginField.email)){
                this.$set(this.errors, 'email', this.messages.wrongEmail)
                this.formValid = false;
            }

            if(this.loginField.password && !this.validPassword(this.loginField.password)){
                this.$set(this.errors, 'password', this.messages.wrongPassword)
                this.formValid = false;
            }
            
            
            if(this.formValid){
                this.loginSubmit();
                this.formValid = false;
            }
        },

        // this method is created for validate create account form field
        createValidateForm: function () {
            this.registerErrors = {
                name: '',
                email: '',
                age: '',
                password: '',
                confirmPassword: ''
            }

            this.passwordError = ""
            this.createFormValid = true; 

            if (!this.registerField.name) {
                this.$set(this.registerErrors, 'name', this.messages.blankName)
                this.createFormValid = false;
            }

            if(this.registerField.name && !this.validName(this.registerField.name)){
                this.$set(this.registerErrors, 'name', this.messages.wrongName)
                this.createFormValid = false;
            }

            if (!this.registerField.age) {
                this.$set(this.registerErrors, 'age', this.messages.blankAge)
                this.createFormValid = false;
            }

            if (!this.registerField.email) {
                this.$set(this.registerErrors, 'email', this.messages.writeEmail)
                this.createFormValid = false;
            }
            if ( !this.registerField.password) {
                this.$set(this.registerErrors, 'password', this.messages.writePassword)
                this.createFormValid = false;
            }
            if ( !this.registerField.age) {
                this.$set(this.registerErrors, 'age', this.messages.blankAge)
                this.createFormValid = false;
            }
            if ( !this.registerField.confirmPassword) {
                this.$set(this.registerErrors, 'confirmPassword', this.messages.blankConfirmPassword)
                this.createFormValid = false;
            }

            if(this.registerField.email && !this.validEmail(this.registerField.email)){
                this.$set(this.registerErrors, 'email', this.messages.wrongEmail)
                this.createFormValid = false;
            }

            if(this.registerField.password && !this.validPassword(this.registerField.password)){
                this.$set(this.registerErrors, 'password', this.messages.wrongPassword)
                this.createFormValid = false;
            }
            
            if(this.registerField.confirmPassword && !this.validConfirmPassword(this.registerField.confirmPassword)){
                this.$set(this.registerErrors, 'confirmPassword', this.messages.wrongPassword)
                this.createFormValid = false;
            }
            
            if(this.registerField.confirmPassword !== '' && this.registerField.password  !== this.registerField.confirmPassword){
               this.$set(this.registerErrors, 'confirmPassword', this.messages.differentPassword);
               this.createFormValid = false;
            }
            if(this.createFormValid){
                this.submitted = true;
                console.log(this.createFormValid)
            }
        },

        // this method is created for login user
        loginSubmit : function() {
            
            this.submitted = true;
            let loginAPI = this.baseURL+'postLogin';
            let formData = new FormData();
                
            formData.append('email', this.loginField.email);
            formData.append('password', this.loginField.password);
            
            fetch(loginAPI, {
            body: formData,
            method: "post"
                }).then(async response => {
                    const data = await response.json(); 

                    console.log('response ',  data  )
                    
                    if(data.StatusCode === "0"){
                        this.$set(this.errors, 'password', data.Message)
                         this.submitted = false;
                    }

                    if (response.status === 200) {
                        if(data.Message === 'Success'){
                            this.$session.start()
                            this.$session.set('token', JSON.stringify(data.SessionToken))

                            this.loginError = data.Message;
                            this.submitted = false;
                            this.loginField.email = "";
                            this.loginField.password = "";
                            this.loginError = "";
                            this.userId = data.userId
                            this.$cookies.remove("LogoutToken");
                            this.$cookies.set('LoginToken', data.SessionToken);
                            window.location.href = this.baseURL+'user/owner.html';
                        }
                    }
                    
                 }, (error) => {
                    console.log('error: ',error);
            });
           
        },

        // this method is created for reset password
        resetSubmit : function(){
            this.submitted = true;
            let ForgotAPI = this.baseURL+'postForgotPassword';
            let formData = new FormData();
                
            formData.append('email', this.resetEmail);
            
            fetch(ForgotAPI, {
            body: formData,
            method: "post"
                }).then(async response => {
                const data = await response.json();
                    this.resetError = data.Message; 
                    this.resetSuccess = false;
                    this.submitted = false;
                    if(data.StatusCode === '1'){
                        this.resetEmail = "";
                        this.resetSuccess = true;

                        setTimeout(() => {
                            this.activeForm = 'Login'
                            this.resetError = "";
                        }, 3000);
                    }
                 }, (error) => {
                    console.log('error: ',error);
            });
        },

        // this method is created for open register form
        registerForm : function(){
            
            this.activeForm = 'Signup';

            this.resetError =  "";
            this.errors = {
                email: "",
                password: ""
            }
            this.registerErrors = {
                name: '',
                email: '',
                age: '',
                password: '',
                confirmPassword: ''
            }
        },

        // this method is created for open login form
        loginForm : function(){
            
            this.activeForm = 'Login'

            this.resetError =  "";
            this.errors = {
                email: "",
                password: ""
            }
            this.registerErrors = {
                name: '',
                email: '',
                age: '',
                password: '',
                confirmPassword: ''
            }
        },

        // this method is created for open reset password form
        forgotForm : function(){
            this.activeForm = 'Reset'            
            this.resetError =  "";
            this.errors = {
                email: "",
                password: ""
            }
            this.registerErrors = {
                name: '',
                email: '',
                age: '',
                password: '',
                confirmPassword: ''
            }
        },

        // this method is created for captcha verify
        verifyMethod : function(){
            this.btnDisabled = false
        }
    }
}
</script>
<style scoped>
.home-video video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    transition: 1s opacity;
}
.home-video + div {
    position: relative;
    z-index: 2;
}
</style>