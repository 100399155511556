import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import MyFeed from '../views/MyFeed.vue'
import InvitationDetails from '../views/InvitationDetails.vue'
import InvitationListing from '../views/InvitationListing.vue'
import SugarDates from '../views/SugarDates.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { 
      title: 'Login',
      template: 2
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      title: 'Loungen',
      template: 3
    }
  },
  {
    path: '/mit-feed',
    name: 'MyFeed',
    component: MyFeed,
    meta: { 
      title: 'Mit Feed',
      template: 1
    }
  },
  {
    path: '/invitation-details',
    name: 'Invitation-details',
    component: InvitationDetails,
    meta: { 
      title: 'Public Invitation',
      template: 1
    }
  },
  {
    path: '/invitations',
    name: 'InvitationListing',
    component: InvitationListing,
    meta: { 
      title: 'Invitations',
      template: 1
    }
  },
  {
    path: '/sugar-dates',
    name: 'SugarDates',
    component: SugarDates,
    meta: { 
      title: 'SugarDates',
      template: 1
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes,
  scrollBehavior( ) {
    // always scroll to top ,to , from, savedPosition
    return { top: 0 }
  },
})

export default router
