<template>
  <!-- Modal -->
<div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-hidden="true">
  <div :class="'modal-dialog '+modalClass" role="document">
    <div :class="header ? '' : 'dark-popup'" class="modal-content">
      <div class="modal-content-inner">
        <div :class="header ? '' : 'blank-header'" class="modal-header">
          <h5 v-if="modalTitle" class="modal-title">{{modalTitle}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div v-if="modalId == 'membership'" class="membership">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="member-box">
                  <h3>Guldnøgle 1</h3>
                  <div class="membership-month">
                    1 måned
                  </div>
                  <div class="membership-percent">
                      <p><strong>&nbsp;</strong>
                      </p>
                  </div>
                  <h4 class="mebership-price-heading">189,00  Kr.</h4>
                  <p class="per-month">Pr. md.</p>
                    <p class="plan-detail"></p>
                    <b class="membership-final-price">189,00  Kr.</b>
                  
                  <div class="select-box-button">
                    <p><button class="btn select-btn" onclick="getMembership('1','18900','1');">Vælg</button></p>
                  </div>
                </div>
            </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="member-box">
                  <h3>Guldnøgle 2</h3>
                  <div class="membership-month">
                    3 måneder 
                  </div>
                  <div class="membership-percent">
                    <p><strong>15%</strong> Guld Rabat</p>
                  </div>
                  <h4 class="mebership-price-heading">169,15  Kr.</h4>
                  <p class="per-month">Pr. md.</p>
                  <p class="plan-detail">
                    <span>597,00  Kr.</span>
                    <span class="save-text">   Spar
                    89,55  Kr.</span>
                  </p>
                  <b class="membership-final-price">507,45  Kr.</b>
                  <div class="select-box-button">
                    <p><button class="btn select-btn" onclick="getMembership('3','50745','2');">Vælg</button></p>
                  </div>
                </div>
            </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="member-box">
                  <h3>Guldnøgle 3</h3>
                  <div class="membership-month">
                      6 måneder 
                  </div>
                  <div class="membership-percent">
                    <p><strong>25%</strong> Guld Rabat</p>
                  </div>
                  <h4 class="mebership-price-heading">141,75  Kr.</h4>
                  <p class="per-month">Pr. md.</p>
                  <p class="plan-detail">
                        <span>1.134,00  Kr.</span>
                        <span class="save-text">   Spar
                        283,50  Kr.</span>
                  </p>
                  <b class="membership-final-price">850,50  Kr.</b>
                  <div class="select-box-button">
                    <p><button class="btn select-btn" onclick="getMembership('6','85050','3');">Vælg</button></p>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="member-box-gold">
                  <h3>Guldnøgle 4</h3>
                  <div class="membership-month-gold">
                      12 måneder
                  </div>
                  <div class="membership-percent">
                    <p><strong>50%</strong> Guld Rabat</p>
                  </div>
                  <h4 class="mebership-price-heading">94,50  Kr.</h4>
                  <p class="per-month-gold">Pr. md.</p>
                  <p class="plan-detail">
                        <span>1.134,00  Kr.</span>
                        <span class="save-text">Spar 1.134,00  Kr.</span>
                  </p>
                  <b class="membership-final-price">1.134,00  Kr.</b>
                  <div class="select-box-button">
                    <button class="btn select-btn" onclick="getMembership('12','113400','4');">Vælg</button>
                  </div>
                </div>
            </div>
            </div>
            <div class="text-center pt-5 pb-5">
              <img src="./../assets/images/line.png" class="pb-4" alt="border">
              <h3 class="text-gold">{{modalContent.title}}</h3>
              <h5>{{modalContent.subtitle}}</h5>
              <h3  class="pt-2 pb-2"><strong>{{modalContent.price}}</strong></h3>
              <a :href="modalContent.action" class="btn select-btn">Vælg</a>
            </div>
          </div>
         
          <!-- modal list  -->
          <ul v-if="modalId == 'plan-description'">
              <li v-if="modalContent.point1">{{modalContent.point1}}</li>
              <li v-if="modalContent.point2">{{modalContent.point2}}</li>
              <li v-if="modalContent.point3">{{modalContent.point3}}</li>
          </ul>
          <!-- <div v-if="modal == 'iframe'">
            <iframe :src="modalContent.url" height="73" width="220" :data-title="modalTitle"></iframe>
          </div> -->

          <div v-if="modalId == 'deleted-user'">
            <p class="text-center">{{modalContent.title}}</p>
          </div>

          <div v-if="modalId == 'deleted-invitation'">
            <p class="text-center">{{modalContent.title}} <a :href="this.baseURL+'user/adddating.html'" target="_blank"><u>hér</u></a></p>
  
          </div>
        </div>
        <div v-if="modalId == 'plan-description'" class="modal-footer">
          <a :href="baseURL+'user/my_membership?planid=1'" class="btn btn-black">{{modalButtonLabel}}</a>
        </div>
      </div>
    </div>
  </div>
   
</div>
</template>

<script>
import Icons from './../assets/images/icons.png';
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
    name: 'Modal',
    data() {
      return{
        icons :  Icons,
        baseURL: process.env.VUE_APP_BASE_URL,
        loadData: '1',
        value: "Alex",
        load: false,
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
            reachY : 'end',
        }
      }
    },
    props:{
        modalId: String,
        modal: String,
        modalTitle: String,
        modalContent: Object,
        modalClass: String,
        modalButton: String,
        modalButtonLabel: String,
        header: String,
        popup: String,
        kk: Number,
        userMembership: String,
        profileLink: String
    },
    mounted(){
      
    },
    methods: {
      
      // this method is created for get larg icon
      getLargIcon: function(id){
            if(id==1){
               var largeIcon='large_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='large_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='large_icon-sugar-dad';
            }else if(id==4){
                largeIcon='large_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='large_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

      // this method is created for get small icon
      getSmallIcon: function(id){
          if(id==1){
              var largeIcon='small_icon-sugar-baby-male'; 
          }else if(id == 2){
              largeIcon='small_icon-sugar-baby-female';              
          }else if(id==3){
              largeIcon='small_icon-sugar-dad';
          }else if(id==4){
              largeIcon='small_icon-sugar-mom';              
          }else if(id==5){
              largeIcon='small_icon-toy-boys-mand';              
          }
          return largeIcon;  
      },
    }
}
</script>

<style>
.tooltip{
  top:0px;
  left: 0px;
}
</style>