<template> 
    <div class="start-post" :id="postIds">
        <div class="post-header">
            <div class="row align-items-center">
                <div class="col-sm-5">
                    <div class="d-flex align-items-center">
                        <a class="profile-sm img-wrap" >
                            <img src="./../assets/images/admin_profile_image.jpg" alt="Profile" class="profile-sm">
                        </a>
                        <div class="admin-name pl-1">
                            <h5 class="m-0 text-white">Admin Annie</h5>
                            <p class="m-0">{{convertUTC(item.flood_created_date)}}</p>
                        </div>
                    </div>
                </div>
                <div :class="liked ? 'liked-press' : 'col-sm-7 pt-1 pt-sm-0'" class="col-sm-7 pt-1 pt-sm-0">
                    <div class="d-flex justify-content-end align-items-center text-white">
                        <div v-if="item.feedReactions.totalReactionCount >= 1" class="pl-2 mr-0 mr-md-3 pr-1 dropdown ">
                            <button :disabled='btnShow' type="button" class="btn-transparent dropdown-toggle" data-toggle="dropdown">
                                <span class="liked mr-1">
                                   <span v-for="(index, i) in reactionCount.slice(0,2)" :key="i">
                                        <img alt="img" v-if="index.id === '1' && item.feedReactions.likeReactionCount != 0" src="./../assets/images/like.png">
                                        <img alt="img" v-else-if="index.id === '2' && item.feedReactions.loveReactionCount != 0" src="./../assets/images/love.png">
                                        <img alt="img" v-else-if="index.id === '3' && item.feedReactions.smileReactionCount  != 0" src="./../assets/images/haha.png">
                                        <img alt="img" v-else-if="index.id === '4' && item.feedReactions.careReactionCount  != 0" src="./../assets/images/care.png"> 
                                        <img alt="img" v-else-if="index.id === '5' && item.feedReactions.wowReactionCount  != 0" src="./../assets/images/wow.png">
                                        <img alt="img" v-else-if="index.id === '6' && item.feedReactions.sadReactionCount  != 0" src="./../assets/images/sad.png">
                                   </span>
                                </span>
                                <span>{{item.feedReactions.totalReactionCount}}</span>
                            </button>
                            <div class="dropdown-menu liked-count pt-3 pl-3 pr-3">
                                <div class="row">
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/like.png" class="like">
                                        <span>{{item.feedReactions.likeReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2" >
                                       <img alt="img" src="./../assets/images/love.png" class="love">
                                        <span>{{item.feedReactions.loveReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/haha.png" class="haha">
                                        <span>{{item.feedReactions.smileReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/care.png" class="care"> 
                                        <span>{{item.feedReactions.careReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2" >
                                       <img alt="img" src="./../assets/images/wow.png" class="wow">
                                        <span>{{item.feedReactions.wowReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/sad.png" class="sad">
                                        <span>{{item.feedReactions.sadReactionCount}}</span>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        <div :class="activeLike ? 'show active-like' : ''" class="dropdown dropup like-thumb">
                          
                            <button
                            v-long-press="300"
                            v-on:mouseover="mouseover"
                            v-on:mouseleave="mouseleave"
                            @long-press-start="onLongPressStart"
                            v-on:click="likeClick(item.reaction === 0 ? '1' : item.reaction, item.id, item.reaction, 1)"
                             type="button" class="btn-transparent dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                               
                                <img alt="img" v-if="item.reaction === 0 || item.reaction === null" src="./../assets/images/like_unselect.png">
                                <img alt="img" v-else-if="item.reaction === '1'" src="./../assets/images/like_selected.png">
                                <img alt="img" v-else-if="item.reaction === '2'" src="./../assets/images/love.png">
                                <img alt="img" v-else-if="item.reaction === '3'" src="./../assets/images/care.png">
                                <img alt="img" v-else-if="item.reaction === '4'" src="./../assets/images/haha.png"> 
                                <img alt="img" v-else-if="item.reaction === '5'" src="./../assets/images/wow.png">
                                <img alt="img" v-else-if="item.reaction === '6'" src="./../assets/images/sad.png">

                            </button>
                            <div v-on:click="removeActiveLike()" class="like-wrap"></div>
                            <div  :class="activeLike ? 'show' : ''" class="dropdown-menu like-menu">
                                <span :disabled='btnShow' class="like" v-on:click="likeClick('1', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/like.png" >
                                </span>
                                <span :disabled='btnShow' class="love" v-on:click="likeClick('2', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/love.png" class="">
                                </span>
                                <span :disabled='btnShow' class="care" v-on:click="likeClick('3', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/care.png" class=""> 
                                </span>
                                <span :disabled='btnShow' class="haha" v-on:click="likeClick('4', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/haha.png" class="">
                                </span>
                                <span :disabled='btnShow' class="wow" v-on:click="likeClick('5', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/wow.png" class="">
                                </span>
                                <span :disabled='btnShow' class="sad" v-on:click="likeClick('6', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/sad.png" class="">  
                                </span>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="feed-content">
            <div :class="readMore[postIds] ? 'd-flex' : 'd-flex align-items-center'">
                <div class="col-md-3 col-sm-4 pb-2 pb-sm-0 pl-0 pr-0">
                    <a target="_blank" :href="item.newsfeeds_link" class="img-wrap">
                        <vue-load-image>
                            <img alt="img" slot="image" class="post-img img-fluid rounded" :src="item.feed_image" />
                            <div slot="preloader" class="hart-loader"></div>
                            <div slot="error"></div>
                        </vue-load-image>
                    </a>  
                </div>
                <div class="col-md-9 col-sm-8 pl-0 pl-sm-3 pr-0">
                    <p class="mb-1 feed-title">Nyheder</p>
                    <h5 class="text-white mb-2"><a target="_blank" :href="item.newsfeeds_link">{{item.feed_title}}</a></h5>
                    
                    <div v-if="item.feed_description != ''">
                        <div class="admin_short_description less mb-0 text-left" v-if="!readMore[item.invitation_id]">
                            <div class="adminpost-description" v-html="item.feed_description.length > textlength ? item.feed_description.substring(0, textlength) + '...' : item.feed_description"></div>
                            <a v-if="!readMore[item.invitation_id]" target="_blank" :href="item.newsfeeds_link" :class="item.feed_description.length > textlength ? '' : 'd-none'" class="more-link">Se mere</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
   
    </div>
</template>

<script>
import LongPress from 'vue-directive-long-press';
import VueLoadImage from 'vue-load-image';
import moment from 'moment';
import axios from 'axios';

export default {
    components: {
        'vue-load-image': VueLoadImage
    },
    directives: {
        'long-press': LongPress
    },
    props: ['page', 'item', 'logedinUser', 'userMembership', 'postIds'],
    data: () => ({
        baseURL: process.env.VUE_APP_BASE_URL,
        readMore: {},
        textlength: 70,
        reactionCount:[],
        activeLike: false,
        btnShow: false,
        liked:false,
        accessToken: ''
    }),
    beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
    mounted() {  	  
        this.getMax();
    },
    methods: {
        // this methos is created for convert date on UTC format
        convertUTC: function(time){
            let local = moment.utc(time).local().format('DD-MM-YYYY hh:mm');
            return local;
        },

        // this method is created for open reaction on hoher icon
        mouseover: function(){
            setTimeout(() => this.activeLike = true, 500);
        },

        // this method is created for clode reaction box on mouse leave    
        mouseleave: function(){
             setTimeout(() => this.activeLike = false, 2000);
        },

        // this method is created for active max reaction icon
       getMax:function () {
            this.reactionCount = [];
            this.reactionCount.push(
                {'id': '1','count': this.item.feedReactions.likeReactionCount},
                {'id': '2','count': this.item.feedReactions.loveReactionCount},
                {'id': '3','count': this.item.feedReactions.smileReactionCount},
                {'id': '4','count': this.item.feedReactions.careReactionCount},
                {'id': '5','count': this.item.feedReactions.wowReactionCount},
                {'id': '6','count': this.item.feedReactions.sadReactionCount}
            );
            this.reactionCount =  this.reactionCount.sort(function(a, b) {return b.count - a.count })
        },

        // this method is created for open reaction box
        onLongPressStart () {
        // triggers after 300ms of mousedown
            
            if(this.activeLike === true){
                this.activeLike = false;
            }else{
                this.activeLike = true;
            }
            // console.log('Long Press')
        },

        // this method is created for like post
        likeClick: function(type, id, userReaction, key){
            if(this.btnShow === false){
                this.btnShow = true;
                if(userReaction === 0){
                    var lastReactionType = "";
                }else{
                    lastReactionType = userReaction;
                }
                if(type === userReaction){
                    var action = 'REMOVE';
                    this.liked = false;
                }else{
                    action = 'ADD';
                    this.liked = true;
                    this.$set(this.item, 'reaction', type)
                }

                if(key === 2){
                    this.activeLike = false
                }
                let formData = new FormData();
                
                formData.append('userId', this.accessToken);
                formData.append('postId', id);
                formData.append('reactionType', type);
                formData.append('action', action);
                formData.append('lastReactionType', lastReactionType);
                
                axios.post('addAndRemoveFeedReactions', formData).then(response => {
                const data = response.data;

                        const result = data.result;
                        this.$set(this.item.feedReactions, 'totalReactionCount', result.totalReactionCount)
                        this.$set(this.item, 'reaction', result.userReaction)
                        this.$set(this.item.feedReactions, 'likeReactionCount', result.likeReactionCount)
                        this.$set(this.item.feedReactions, 'loveReactionCount', result.loveReactionCount)
                        this.$set(this.item.feedReactions, 'smileReactionCount', result.smileReactionCount)
                        this.$set(this.item.feedReactions, 'careReactionCount', result.careReactionCount)
                        this.$set(this.item.feedReactions, 'wowReactionCount', result.wowReactionCount)
                        this.$set(this.item.feedReactions, 'sadReactionCount', result.sadReactionCount)
                        this.$set(this.item.feedReactions, 'id', result.id)
                        this.$set(this.item.feedReactions, 'postId', result.postId)
                        this.btnShow = false;
                        this.getMax()
                        this.liked = false;

                    }, (error) => {
                    console.log('error: ',error)
                });
            }
        },
        
         // this method is created for unlike post
        removeActiveLike:function(){
            this.activeLike = false;
            console.log('clicked')
        },

    }
}
</script>

<style>

</style>