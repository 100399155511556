<template>
    <div id="sugardates" class="container">
        <div class="breadcrumb text-center ml-3 mr-3">
            <h1><span>Sugar Dates <img alt="img" src="./../assets/images/headingicon.png"></span></h1>
            <div class="breadcrumb-url">
                <a :href="baseURL+'user/owner.html'">Mit Sted</a>><span>Sugar Dates</span>
            </div>
        </div>
        <div class="my-post-bg  pt-2 pl-3 pb-3 pr-3 ml-0 mr-0 ml-sm-3 mr-sm-3">
            <ul class="nav mb-3" id="custom-tab" role="tablist">
                <li v-for="(cat, idx) in Category" :id="idx" :key="idx" class="nav-item" role="presentation">
                    <a :class="cat.id === activeTab ? ' active' : ''" class="nav-link" v-on:click="tabClick(cat.id)" :id="cat.slug+'-tab'" data-toggle="pill" href="#item.slug" role="tab" :aria-controls="cat.slug" aria-selected="true">{{cat.name}}</a>
                </li>
            </ul>
            
            <div class="tab-content text-light" id="custom-tabContent">
                <div v-for="(item, index) in Category" :key="'tab-'+index" v-bind:class="item.id === activeTab ? 'in active show' : ''" class="tab-pane fade" :id="item.slug" role="tabpanel" >
                    <div class="row">
                        <div class="col-sm-7 text-center text-sm-left">
                            <div class="form-group search">
                                <input type="text" class="form-control" v-on:input="SearchName" v-model="searchQuery" name="search" placeholder="Søg profil navn">
                                <span class="search-icon"></span>
                            </div>
                        </div>
                        <div class="col-sm-5 text-center text-sm-right pt-3 pt-sm-0">
                            <!-- <button class="filter-btn btn-outline" v-on:click="toggleClass">Søg Profiler <span class="filter-icon "></span></button> -->
                             <!-- v-on:change="sortByData($event)" v-model="sortBy" -->
                            <select class="category-filter" v-on:change="sortByData($event)" v-model="categorySelect">
                                <option value="0" selected>Alle</option>
                                <option value="1">Sølv</option>
                                <option value="2">Guld</option>
                            </select>
                        </div>
                    </div>
                    <div class="filter-box show">
                        <div id="filter">
                            <div class="row pl-1 pr-1 align-items-end">
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pr-1 pl-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>Alder</label>
                                            <model-list-select :list="age"
                                                v-model="minAge"
                                                option-value="value"
                                                option-text="text"
                                                name="age"
                                                placeholder="Alder">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pl-1 pr-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>&nbsp;</label>
                                            <model-list-select :list="age"
                                                v-model="maxAge"
                                                option-value="value"
                                                option-text="text"
                                                name="maxAge"
                                                placeholder="Alder">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pr-1 pl-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>Højde</label>
                                            <model-list-select :list="height"
                                                v-model="minHeight"
                                                option-value="value"
                                                option-text="text"
                                                name="height"
                                                placeholder="Højde">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pl-1 pr-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>&nbsp;</label>
                                            <model-list-select :list="height"
                                                v-model="maxHeight"
                                                option-value="value"
                                                option-text="text"
                                                name="maxHeight"
                                                placeholder="Højde">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pr-1 pl-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>Vægt</label>
                                            <model-list-select :list="weight"
                                                v-model="minWeight"
                                                option-value="value"
                                                option-text="text"
                                                name="weight"
                                                placeholder="Vægt">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pl-1 pr-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>&nbsp;</label>
                                            <model-list-select :list="weight"
                                                v-model="maxWeight"
                                                option-value="value"
                                                option-text="text"
                                                name="maxWeight"
                                                placeholder="Vægt">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pr-1 pl-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>postnr</label>
                                            <model-list-select 
                                                :list="postCode"
                                                option-value="city_id"
                                                option-text="postCode"
                                                v-model="fromZipCode"
                                                name="postCode"
                                                placeholder="postnr">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 col-lg-2 pl-1 pr-2">
                                    <div class="form-group">
                                        <span class="block input-icon input-icon-right">
                                            <label>&nbsp;</label>
                                            <model-list-select
                                                :list="postCode"
                                                v-model="toZipCode"
                                                option-value="city_id"
                                                option-text="postCode"
                                                name="maxPostCode"
                                                placeholder="postnr">
                                            </model-list-select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-8 col-lg-2 pl-1 text-center text-md-left">
                                    <label>&nbsp;</label>
                                    <button @click="submit" type="submit" class="btn btn-primary btn-sm pl-4 pr-4 mt-2 mb-sm-2">Søg</button>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    <div v-if="loading" class="loader">
                    </div>
                    <div v-else>
                        <div v-if="Profiles" class="row pl-1 pr-1">
                        <div v-for="(item, i) in Profiles" :key="i" class="col-md-6 pl-2 pr-2 pt-3">
                            <div class="profile-box-wrap">
                                <div class="users-members-box d-flex">
                                    <div class="users-members-box-image pr-1">
                                        <a v-on:click="openDetailPage()" :href="item.profile_link">
                                            <div :class="userMembership === '2' ? '' : 'img-blur'" class="img-wrap profile-medium2">
                                                <img :src="item.avatar" alt="Profile" class="profile-medium2 mr-0">
                                            </div>
                                            <div :class="item.member === '2' ? 'sugardate-member-gold' : 'sugardate-member-silver'">{{item.member === '2' ? 'Guld' : 'Sølv'}}</div>
                                        </a>
                                    </div>
                                    <div>
                                        <div class="users-members-header">
                                            <h5 class="mb-1"><a v-on:click="openDetailPage()" :href="item.profile_link">{{item.name}}</a>
                                                <span class="profile-icon">
                                                    <a :class="getLargIcon(item.own)" v-tooltip="getLargIconTitle(item.own)" href=#></a>
                                                    <a :class="getSmallIcon(item.play)" v-tooltip="getLargIconTitle(item.play)" href=#></a>
                                                </span>
                                            </h5>
                                            <button v-on:click="addRemoveToFavourite(item.is_favourite, item.id, i)" :class="item.is_favourite === '1' ? 'red-heart-icon-active' : 'red-heart-icon'" :disabled='btnShow'></button>
                                        </div>
                                        <a v-on:click="openDetailPage()" :href="item.profile_link">
                                        <p class="mb-0"><span><b>Alder:</b> {{item.age}} år,</span> <span><b>Højde:</b> {{item.height}} cm,</span> <span><b>Vægt:</b> {{item.weight}} kg,</span> <span><b>Postnr:</b> {{item.code}},</span> <span><b>By:</b> {{item.city}}</span></p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="loader === true && totalCount != Profiles.length" class="col-md-12 text-center pt-3">
                            <img src="./../assets/images/heart.svg" alt="loader">
                        </div>

                    </div>
                    <div v-else class="no-records text-center p-3">
                        <p>Intet nyt lige nu. Prøv igen senere.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ModelListSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import {Category} from './../const/index.js';
import {Height} from './../const/index.js';
import {Age} from './../const/index.js';
import {Weight} from './../const/index.js';
import EventBus from './../bus';
import axios from 'axios';

export default {
  name: 'SugarDates',
  components: {
    ModelListSelect
  },
  data(){
    return{
        baseURL: process.env.VUE_APP_BASE_URL,
        filter: false,
        activeTab: '1',
        pageNo: '1',
        totalCount: '',
        loading: true,
        Profiles: [],
        activeSearch: 0,
        userMembership: '',
        Category: Category,
        searchQuery: '',
        loader: false,
        pageCount: '',
        increaseLoad: false,
        age: Age,
        postCode: [],
        weight: Weight,
        height: Height,
        minAge: { value: '18', text: '18 år' },
        maxAge: { value: '80', text: '80 år' },
        minHeight: { value: '140', text: '140 cm' },
        maxHeight: { value: '250', text: '250 cm' },
        minWeight: { value: '40', text: '40 kg' },
        maxWeight: { value: '150', text: '150 kg' },
        fromZipCode: {city_id: '42910', postCode: '1000'},
        toZipCode: {city_id: '43503', postCode: '9990'},
        categorySelect: '0',
        range: '500',
        favouriteProfile: "ADD",
        btnShow: false,
        logedinUser: '',
        // openDetail: false,
        clickDetail: false,
        enableLoadData:true,
        accessToken: ''
    }
  },
  computed: {
    filteredList() {
      return this.postList.filter(post => {
        return post.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  mounted(){
    this.activeTab = this.$route.query.type;
    EventBus.$on('logedinUser', this.getLogedinUser);
    EventBus.$on('userMembership', this.getMembership);
    const listElm = document.querySelector('.page');
    var height = document.getElementsByClassName('footer')[0].clientHeight;
 
    listElm.addEventListener('scroll', () => {
      if(Math.ceil(listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight - height && this.enableLoadData) {
        this.richEnd();
        this.enableLoadData = false;
      }
     });
  },
  beforeMount(){
      this.getCodeList();
      this.accessToken = JSON.parse(this.$session.get('token'));
  },
  methods: {
    openDetailPage(){
        this.clickDetail = false;
        setTimeout(() => { this.clickDetail = true }, 5000)
    },
    getNotifications(){
        if(this.clickDetail === true ){
            this.loading= true;
            this.totalCount= '';
            this.Profiles= [];
            this.pageNo = '1',
            this.getSugarDates()
        }
    },
    SearchName(){
        if(this.searchQuery.length >= 1){
            this.activeSearch = 1;
        }else{
            this.activeSearch = 0;
        }
        this.loading = true;
        this.totalCount= '';
        this.Profiles= [];
        this.pageNo = '1',
        this.getSugarDates();
    },
    richEnd() {
         if(this.increaseLoad === true){
          var i = this.pageNo;
          if(i < this.pageCount){
              this.pageNo = ++i;
              
              this.loadSugarDates();
              this.increaseLoad = false;
              this.loader = true;
          }else{
            this.loader = false;
          } 
        }   
    },
    filterDataByCategory: function(data){
        if(this.categorySelect === '0'){
            return data
        }else{
            return data.filter(product => !product.category.indexOf(this.categorySelect))
        }
    },

    getLogedinUser(value) {
        this.logedinUser = value;
        this.loading= true;
        this.getSugarDates();
    },
    sortByData : function(event){
        this.categorySelect = event.target.value;
        this.loading= true;
        this.totalCount= '';
        this.Profiles= [];
        this.pageNo = '1',
        this.getSugarDates();
    },
    getCodeList: function(){

        axios.get('getPostCodeList').then(response => (
        this.postCode = response.data.result,
        this.postCode =  this.postCode.sort(function(a, b) {return a.postCode - b.postCode })
        )).finally(() => (this.loading = false));
    },
    getMembership(value) {
      this.userMembership = value;
    },
      tabClick(value){ 
          if(this.$route.query.type != value){ 
            this.activeTab = value;
            this.$router.push({ name: 'SugarDates', query: { type: value } }) 
            
            this.fromZipCode = {city_id: '42910', postCode: '1000'},
            this.toZipCode = {city_id: '43503', postCode: '9990'},
            this.minHeight = {value: '140', text: '140 cm'},
            this.maxHeight = { value: '250', text: '250 cm'},
            this.minWeight = {value: '40', text: '40 kg'},
            this.maxWeight = { value: '150', text: '150 kg'},
            this.minAge = { value: '18', text: '18 år'},
            this.maxAge = { value: '80', text: '80 år'}
            
            this.loading= true;
            this.totalCount= '';
            this.Profiles= [];
            this.pageNo = '1',
            this.searchQuery= '';
            this.categorySelect = 0;
            this.getSugarDates();
          }
       },
       
        getLargIcon: function(id){
            if(id==1){
               var largeIcon='large_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='large_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='large_icon-sugar-dad';
            }else if(id==4){
                largeIcon='large_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='large_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },
        getLargIconTitle: function(id){
            if(id==1){
               var largeIcontitle='Sugar Baby (M)'; 
            }else if(id == 2){
                largeIcontitle='Sugar Baby (F)';              
            }else if(id==3){
                largeIcontitle='Sugar Dad';
            }else if(id==4){
                largeIcontitle='Cougar';              
            }else if(id==5){
                largeIcontitle='ToyBoy';              
            }
            return largeIcontitle;  
        },
        getSmallIcon: function(id){
            if(id==1){
               var largeIcon='small_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='small_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='small_icon-sugar-dad';
            }else if(id==4){
                largeIcon='small_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='small_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },
        submit() {
            this.loading = true;
            this.totalCount= '';
            this.Profiles= [];
            this.pageNo = '1',
            this.getSugarDates();
        },
        addRemoveToFavourite: function(favourite, id, index){
              
            if(this.btnShow === false){
                this.btnShow = true;
                if(favourite === "1"){
                    this.favouriteProfile = 'REMOVE';
                    this.$set(this.Profiles[index], 'is_favourite', '0')
                }else{
                    this.favouriteProfile = 'ADD';
                    this.$set(this.Profiles[index], 'is_favourite', '1')
                }
                
                let formData = new FormData();
                formData.append('favouriteProfile', id);
                formData.append('favouriteBy', this.logedinUser);
                formData.append('action', this.favouriteProfile);
                
                axios.post('addRemoveToFavouriteProfile', formData).then(response => {
                    const data = response.data;
                    console.log(data)
                        if(favourite === "1"){
                            this.$toasted.show("Slettet som Favorit !", {
                                type : 'error',
                                position: 'top-center',
                                theme: 'toasted-primary'
                            });
                        }else{
                        this.$toasted.show("Tilføjet som Favorit !", {
                                type : 'success',
                                position: 'top-center',
                                theme: 'toasted-primary'
                            });
                        }
                        setTimeout(() => { this.btnShow = false }, 2000)

                    }, (error) => {
                    console.log('error: ',error);
                }).finally(() => (this.loading = false));
            }
        },
        getSugarDates: function(){
            //  this.openDetail = false; 
             this.clickDetail = false;
            let formData = new FormData();
            
            formData.append('userId', this.logedinUser);
            formData.append('minAge', this.minAge.value);
            formData.append('maxAge', this.maxAge.value);
            formData.append('minHeight', this.minHeight.value);
            formData.append('maxHeight', this.maxHeight.value);
            formData.append('minWeight', this.minWeight.value);
            formData.append('maxWeight', this.maxWeight.value);
            formData.append('fromZipCode', this.fromZipCode.postCode);
            formData.append('toZipCode', this.toZipCode.postCode);
            formData.append('categoryId', this.activeTab);
            formData.append('pageNo', this.pageNo);
            formData.append('member', this.categorySelect);
            formData.append('search', this.activeSearch);
            formData.append('searchName', this.searchQuery);
            
            axios.post('getSugardates', formData).then(response => {
            const data = response.data;
                    this.Profiles = data.result.data;
                    this.pageCount = data.result.page;
                    this.totalCount = data.result.count;
                    this.increaseLoad = true
                    
                 }, (error) => {
                    console.log('error: ',error);
            }).finally(() => (this.loading = false));
        },
        loadSugarDates: function(){  
            let formData = new FormData();
            formData.append('userId', this.logedinUser);
            formData.append('minAge', this.minAge.value);
            formData.append('maxAge', this.maxAge.value);
            formData.append('minHeight', this.minHeight.value);
            formData.append('maxHeight', this.maxHeight.value);
            formData.append('minWeight', this.minWeight.value);
            formData.append('maxWeight', this.maxWeight.value);
            formData.append('fromZipCode', this.fromZipCode.postCode);
            formData.append('toZipCode', this.toZipCode.postCode);
            formData.append('categoryId', this.activeTab);
            formData.append('pageNo', this.pageNo);
            formData.append('member', this.categorySelect);
            formData.append('search', 0);
            formData.append('searchName', this.searchName);
            
            axios.post('getSugardates', formData).then(response => {
            const data = response.data;
                    // this.Profiles = data.result.data;
                    this.Profiles = this.Profiles.concat(data.result.data),
                    this.loader = false;
                    this.increaseLoad = false;
                    this.enableLoadData = true;
                 }, (error) => {
                    console.log('error: ',error);
            }).finally(() => (this.loading = false, this.increaseLoad = true));
        },
    },
    watch:{
        $route (){
            window.location.reload()
            this.getSugarDates();
        },
    },
    created() {
        this.interval = setInterval(() => this.getNotifications(), 2000);
    }
}
</script>
