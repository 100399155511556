<template>
  <div class="invitation-detail container">
      <div class="breadcrumb text-center ml-3 mr-3">
          <h1><span>Indhold <img alt="img" src="./../assets/images/headingicon.png"></span></h1>
          <div class="breadcrumb-url">
              <a :href="baseURL+'user/owner.html'">Mit Sted</a>><a :href="baseURL+'loungen/#/invitations?type='+getUserType(this.userType)">Mine VIP/Date Opslag</a>><span>Indhold</span>
          </div>
      </div>
     
      <div class="my-post-bg  pt-2 pl-3 pb-3 pr-3 ml-0 mr-0 ml-sm-3 mr-sm-3">
        <div v-if="loading" class="loader">
            
        </div>
        <div v-else>
            <div v-for="item in notificationContent" :key="item.id" class="border-radius bg-dark text-light p-3">
                <div class="row">
                    <div class="col-sm-5 col-md-4 text-center text-sm-left">
                        <span class="black-info">
                            <span v-if="getType === '2'">Date Opslag</span>
                            <span v-else class="text-gold d-flex align-items-center"><img alt="img" class="pr-1" src="./../assets/images/star.png"> VIP</span>
                        </span>
                      </div>
                    <div class="col-md-4 order-3 order-md-2 text-center pt-2">
                        <img v-if="getType === '2' && item.type === 'Creator'" src="./../assets/images/groupicon.png" class="profile-xl" alt="profile">
                        <a v-else :href="item.profile_link" target="_blank">
                            <div :class="userMembership === '2' ? '' : 'img-blur'" class="profile-xl img-wrap">
                                <img :src="baseURL+item.avatar" class="profile-xl" alt="profile">
                            </div>
                        </a>
                        
                    </div>
                    <div class="col-sm-7 col-md-4 order-2 order-md-3 text-center text-sm-right pt-3 pt-sm-0">
                        
                        <div class="">
                            <div v-if="getType === '2' && item.type === 'Creator'" class="mb-2">
                                <a v-if="!item.intrestedUsers.data && !expired" :href="item.edit_link" title="Edit" class="Edit mr-3">
                                    <img alt="img" src="./../assets/images/editicon.png" class="">
                                </a>
                                <a href="#" title="Delete" @click="showModal = true" class="Delete">
                                    <img alt="img" src="./../assets/images/deleteicon.png" class="">
                                </a>
                            </div>
                            <div v-else-if="item.type === 'Creator' && getType === '1'" class="mb-2">
                                <a v-if="item.status === 'Pending' && !expired" :href="item.edit_link" title="Edit" class="Edit mr-3">
                                    <img alt="img" src="./../assets/images/editicon.png" class="">
                                </a>
                                <a href="#" title="Delete" @click="showModal = true" class="Delete">
                                    <img alt="img" src="./../assets/images/deleteicon.png" class="">
                                </a>
                            </div>
                        </div>
                        <div v-if="expired || item.status === 'Reject' || item.status === 'Approved'" class="text-center text-sm-right mb-1 status">
                            <!-- <strong>Status:</strong>  -->
                          
                            <span v-if="item.status === 'Reject' || item.status === 'Approved'">
                                <span v-if="getType === '2' && item.status === 'Pending'" class="text-gold">Afventer</span>
                                <span v-if="item.status === 'Reject'" class="text-danger"> Afvist</span>
                                <span v-if="item.status === 'Approved'" class="text-success"> Accepteret</span>
                            </span>
                            <span v-else-if="expired" class="text-danger"> Udløbet</span>
                        </div>
                        <div class="status" v-if="item.type === 'Creator' && item.status === 'Pending' && !expired && getType === '1'">
                            <!-- <strong>Status:</strong> -->
                            <span class="text-gold"> Afventer</span> 
                        </div>
                        <div class="status" v-if="item.type === 'Receiver' && getType === '2'">
                            <div v-if="item.intrestedUsers.data[0].status === 'Shown Interest' && item.type === 'Receiver' && !expired">
                                <!-- <strong>Status:</strong> -->
                                <span class="text-success"> Vist interesse</span>
                            </div>
                       </div>
                        <p class="created-date1"><span>Oprettet: {{myDate(item.time) | customDate}}</span><br><span>Udløber: {{item.invitation_end_date }}</span></p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div v-if="getType === '1'" class="text-center mb-3 pt-3">
                        <h5>
                            <a target="_blank" :href="item.profile_link">{{item.name}}
                                </a>
                            <span class="profile-icon">
                                <a :class="getLargIcon(item.own)" v-tooltip="getLargIconTitle(item.own)" href=#></a>
                                <a :class="getSmallIcon(item.play)" v-tooltip="getLargIconTitle(item.play)" href=#></a>
                            </span>
                        </h5>
                        <p>{{item.age}} år / {{item.height}} cm / {{item.weight}} kg / {{item.city}} {{item.code}}</p>
                        
                        <a v-if="userMembership === '2'" :href="item.chat_now" target="_blank" class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                        <a v-else data-toggle="modal" data-target="#plan-description"  class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                    </div>
                    <div v-else-if="item.type === 'Receiver'" class="text-center mb-3 pt-3">
                        <h5>
                            <a target="_blank" :href="item.profile_link">{{item.name}}
                                </a>
                            <span class="profile-icon">
                                <a :class="getLargIcon(item.own)" v-tooltip="getLargIconTitle(item.own)" href=#></a>
                                <a :class="getSmallIcon(item.play)" v-tooltip="getLargIconTitle(item.play)" href=#></a>
                            </span>
                        </h5>
                        <p>{{item.age}} år / {{item.height}} cm / {{item.weight}} kg / {{item.city}} {{item.code}}</p>
                        
                        <a v-if="userMembership === '2'" :href="item.chat_now" target="_blank" class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                        <a v-else data-toggle="modal" data-target="#plan-description"  class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                    </div>
                    <div class="col-lg-10 text-center pt-3 pt-sm-4">
                        <h5>{{item.title}}</h5>
                        <p>{{item.description}}</p>

                        <vue-countdown-timer v-if="!expired && item.status === 'Pending' && getType === '1'" :class="expired === true ? 'bg-danger'  :  ''" class="timer mt-3 mb-3"
                            @end_callback="endCallBack('event ended', item.id)"
                            :start-time="myDate(item.time) | formatTimer"
                            :end-time="myDate(item.end_date) | formatTimer"
                            :interval="1000"
                            label-position="begin"
                            :end-text="'Udløbet'"
                            :day-txt="' dag(e)'"
                            :hour-txt="' time(r)'"
                            :minutes-txt="' minut(ter)'"
                            :seconds-txt="' sekund(er)'">
                            <template slot="countdown" slot-scope="scope">
                                <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                <label><span class="minuts">{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}</label>
                                <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                            </template>
                        </vue-countdown-timer>

                        <vue-countdown-timer v-if="!expired && getType === '2'" :class="expired === true ? 'bg-danger'  :  ''" class="timer mt-3 mb-3"
                            @end_callback="endCallBack('event ended', item.id)"
                            :start-time="myDate(item.time) | formatTimer"
                            :end-time="myDate(item.end_date) | formatTimer"
                            :interval="1000"
                            label-position="begin"
                            :end-text="'Udløbet'"
                            :day-txt="' dag(e)'"
                            :hour-txt="' time(r)'"
                            :minutes-txt="' minut(ter)'"
                            :seconds-txt="' sekund(er)'">
                            <template slot="countdown" slot-scope="scope">
                                <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                                <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                                <label><span class="minuts">{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}</label>
                                <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                            </template>
                        </vue-countdown-timer>
                    </div>
                </div>
                <div v-if="userMembership === '2' && item.type === 'Receiver'">
                    <div v-if="getType === '1' && expired === false && item.status === 'Pending'" class=" action-btn text-center pt-3 mb-4">
                        <button v-on:click="takeAction(1)" 
                        
                        class="btn btn-primary btn-long mr-1">Acceptér</button>
                        <button v-on:click="takeAction(2)" 
                        
                        class="btn btn-primary btn-long ml-1">Afvis</button>
                    </div>
                
                </div>
                <div v-else-if="item.type === 'Receiver'">
                    <div v-if="getType === '1' && expired === false && item.status === 'Pending'" class=" action-btn text-center pt-3 mb-4">
                        <button data-toggle="modal" data-target="#plan-description"
                        class="btn btn-primary btn-long mr-1">Acceptér</button>
                        <button data-toggle="modal" data-target="#plan-description"
                        class="btn btn-primary btn-long ml-1">Afvis</button>
                    </div>
                </div>
                <hr v-if="getType === '2' && item.type === 'Creator'">
                <div v-if="getType === '2' && item.type === 'Creator'" class="text-center pt-3">
                    <h4>Vist interesse<span class="total-count"> ({{intrestedUserCount}})</span></h4>
                    <div v-if="item.intrestedUsers.data" class="row pb-3 justify-content-center">
                        <div v-for="(intrest, k) in intrestedUser" :key="k"  class="text-center col-lg-2 col-md-3 col-sm-4 col-6 pt-4 pb-4">
                            <div :class="userMembership === '2' ? '' : 'img-blur'" class="img-wrapper profile-large mb-2">
                                <a :href="intrest.profile_link" target="_blank">
                                    <img v-if="intrest.avatar" :src="intrest.avatar" class=" profile-large" alt="profile">
                                </a>
                            </div>
                            <h6 class="mh-4 mb-0"><a :href="intrest.profile_link" target="_blank">{{intrest.name}}</a></h6>
                            
                            <a v-if="userMembership === '2'" :href="intrest.chat_now" target="_blank" class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                            <a v-else data-toggle="modal" data-target="#plan-description"  class="chat-btn"><img alt="img" src="./../assets/images/chatlarge.png" class="mr-2">Chat</a>
                    
                        </div>
                        <div v-if="intrestedUser.length >= 24" :class="shoBtn === true ? 'mt-4' : ''" class="col-12 text-center">
                             <button v-if="shoBtn === true" class="btn btn-primary" v-on:click="richEnd()">Se mere</button>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <p class="pt-3">Du har ingen aktive visninger</p>
                    </div>
                </div>
            </div>
        </div>
     
      </div>
      <div class="row m-0 mb-2">
        <div v-for="item in bannerImages" :key="item.id" :id="'image-'+item.id" class="col-md-2 col-sm-4 col-4 pl-0 pr-0">
            <div class="below-slider-image">
                <a :href="item.link_path">
                    <img :src="baseURL+item.image" alt="Velkommen" class="img-fluid">
                </a>
            </div>
        </div>     
       </div>

<!-- Modal -->
<div :class="showModal ? 'show' : ''" class="modal fade" id="deleteConfirm" tabindex="-1" aria-hidden="true">
  <div class="modal-shadow" @click="showModal = false"></div>
  <div class="modal-md modal-dialog modal-dialog-centered">
    <div class="modal-content dark-popup">
        <div class="modal-content-inner">
            <div class="modal-header blank-header">
                <h4 class="modal-title text-center" id="deleteConfirmLabel">Vil du slette denne invitation ?</h4>
                <button type="button" class="close" @click="showModal = false">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-footer pt-4">
               <button type="button" @click="deleteInvitation()" class="btn btn-primary btn-sm btn-long">Ja</button>
                <button type="button" class="btn btn-dark btn-sm btn-long" @click="showModal = false" data-dismiss="modal">Nej</button>
                
            </div>
        </div>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment'
import EventBus from './../bus';
import axios from 'axios';

Vue.prototype.moment = moment

export default {
  name: 'InvitationDetails.vue',
  components: {
    
  },
  data(){
    return{
      baseURL: process.env.VUE_APP_BASE_URL,
      bannerImages: [],
      notificationContent: [],
      intrestedUser:[],
      userMembership: '',
      logedinUser:'',
      expired: false,
      shoBtn: false,
      getID: '',
      loadData: '1',
      pageCount: '0',
      getType: '',
      getUser:'1',
      loading: true,
      datingID: '',
      uservip: '',
      userID:'',
      userType: '',
      intrestedUserCount: '',
      showModal: false,
      accessToken: ''
    }
  },
  beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
  mounted() {  	  
    EventBus.$on('logedinUser', this.getLogedinUser)
    EventBus.$on('userMembership', this.getMembership)
    
    this.getID = this.$route.query.id;
    this.getType = this.$route.query.type;
    this.datingID = this.$route.query.datingId;
  },
  methods:{
       // this method is created for read notification
      readNotification: function(){
        
        let readNotification = new FormData();
        readNotification.append('userId', this.accessToken);
        readNotification.append('datingId',  this.getID);
        
        axios.post('updateReadNotification', readNotification).then(response => {
            const data = response.data;
            if(data.message === 'Success'){
                
                // const div = document.querySelector("#notification-"+this.getID);
                // div.classList.remove("latest");
                // console.log(data)
            }
            }, (error) => {
                    console.log('error: ',error)
            });
    },

     // this method is created for update page when timer is end
      yourCallBackFunction () {
        window.location.reload()
    },

     // this method is created for get logedinuser
    getLogedinUser(value) {
        this.logedinUser = value;
        this.getData(this.loadData);
        this.readNotification();
   },

    // this method is created for get user membership
   getMembership(value) {
      this.userMembership = value;
   },

    // this method is created for show expired when timer end
    endCallBack: function() {
        this.expired = true;
    },

     // this method is created for get date 
     myDate: function(val){
        let timestamp = { seconds: val, nanoseconds: 0 }     
        return new Date(timestamp.seconds * 1000)
    },

     // this method is created for get invitation data
    getData: function(loadpage){ 
        let formData = new FormData();
        formData.append('invitationId', this.getID);
        formData.append('type', this.getType);
        formData.append('datingId', this.datingID);
        formData.append('pageNo', loadpage);
        formData.append('userId', this.accessToken);
        formData.append('encryption', this.getID);
        
        axios.post('getInvitationDetail', formData).then(response => {
            const data = response.data;
            this.bannerImages = data.bannerImages.bannerImages;
            this.notificationContent = data.result;
            this.intrestedUser =  this.intrestedUser.concat(data.result[0].intrestedUsers.data);
            this.intrestedUserCount = data.result[0].intrestedUsers.count;
            this.pageCount = data.result[0].intrestedUsers.page;
            this.userID = data.result[0].user_id;
            this.uservip = data.result[0].uservip;
            this.userType = data.result[0].type;
            if(this.pageCount > 0 && loadpage < this.pageCount + 1){
                this.shoBtn = true;
            }else{
                this.shoBtn = false;
            }  
            },
             (error) => {
                    console.log('error: ',error)
            }).finally(() => (this.loading = false));
        },

         // this method is created for load more data
        richEnd() {
            var i = this.loadData;
           
            if(i-1 < this.pageCount){
                this.loadData = ++i;
                this.shoBtn = true;
                this.getData(this.loadData);
                
            }  
        },

         // this method is created for accept invitation
        takeAction: function(type){
            let profileAction = new FormData();
            profileAction.append('id', this.getID);
            profileAction.append('userId', this.accessToken);
            profileAction.append('datingId', this.userID);
            profileAction.append('type', type);
                
            axios.post('acceptOrRejectInvitation', profileAction).then(response => {
            const data = response.data;
                if(data.message === 'Success'){
                    if(type === 1){
                       
                        this.$toasted.show("Invitation accepteret", {
                            type : 'success',
                            position: 'top-center',
                            theme: 'toasted-primary'
                        })
                    }else{
                        this.$toasted.show("Invitation afvist", {
                            type : 'error',
                            position: 'top-center',
                            theme: 'toasted-primary'
                        })
                    }
                    this.getData(this.loadData);
                }

             }, (error) => {
                    console.log('error: ',error);
            });
        },
        
        // this method is created for get invitation type 
        getUserType: function(Type){
            if(Type === 'Creator'){
                var User = 'created';
            }else{
                User = 'received';
            }
            return User;
        },

        // this method is created for get large category icon
        getLargIcon: function(id){
            if(id==1){
               var largeIcon='large_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='large_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='large_icon-sugar-dad';
            }else if(id==4){
                largeIcon='large_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='large_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

        // this method is created for get category title
        getLargIconTitle: function(id){
            if(id==1){
               var largeIcontitle='Sugar Baby (M)'; 
            }else if(id == 2){
                largeIcontitle='Sugar Baby (F)';              
            }else if(id==3){
                largeIcontitle='Sugar Dad';
            }else if(id==4){
                largeIcontitle='Cougar';              
            }else if(id==5){
                largeIcontitle='ToyBoy';              
            }
            return largeIcontitle;  
        },

        // this method is created for get small category icon
        getSmallIcon: function(id){
            if(id==1){
               var largeIcon='small_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='small_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='small_icon-sugar-dad';
            }else if(id==4){
                largeIcon='small_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='small_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

        // this method is created for delete invitation
        deleteInvitation: function(){
            
            
            let deleteAction = new FormData();
            deleteAction.append('id', this.getID);
                
            axios.post('deleteInvitation', deleteAction).then(response => {
            const data = response.data;
                if(data.message === 'Success'){
                    this.$toasted.show("Denne invitation er slettet", {
                        type : 'error',
                        position: 'top-center',
                        theme: 'toasted-primary'
                    })

                    this.$router.push({ name: 'InvitationListing', query: { type: 'created' } })
               
                    window.location.reload()
                }

             }, (error) => {
                    console.log('error: ',error);
            }); 
        }
    },
    watch:{
        $route (to){
            window.location.reload()	
            this.getID = to.query.id;
            this.getType = to.query.type;
            this.datingID = to.query.datingId;
            this.loading = true;
            this.expired = false;
            this.getData(this.loadData);
            this.intrestedUser = [];
            this.intrestedUserCount= '';
            this.pageCount = '0';
        }
    }, 
    beforeRouteLeave(to, from, next) {
         this.yourCallBackFunction();
         this.notificationContent = []
        return next();   
    }

}
</script>