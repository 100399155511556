<template>
    <div class="start-post post-message">
        <div class="post-content">
            <div class="row align-items-center pt-3 pb-3">
                <div v-if="item.postType === 'GALLERYNOTIFICATION'" class="col-sm-12 col-md-12 text-center">
                    <a class="btn btn-primary " v-on:click="openPopup" data-toggle="modal" data-target="#user-profile" >Se online profiler & nye billeder</a>
                    <h5 v-if="userMembership != '2'" class="mb-4 mt-4 text-white">Har du Gæstekort ?</h5>
                    <a  v-if="userMembership != '2'" class="btn btn-primary" target="_blank" :href="baseURL+'user/my_membership?planid=1'">Hent Guldnøglen hér</a>
                </div>
                
                <div v-else class="col-sm-12 col-md-12 text-center">
                    <h5 class="mb-4 text-white">{{getCategoryTitle(item.postType)}}</h5>
                    <a class="btn btn-primary" target="_blank" :href="baseURL+'loungen/#/sugar-dates?type='+getCategory(item.postType)">Ja ! Vis mig mine muligheder.</a>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

export default {
    name: 'AdminMessage',
    props: ['item','userMembership', 'page', 'logedinUser'],
    data(){
        return{
            profilePopup: false,
            baseURL: process.env.VUE_APP_BASE_URL,
        }
    },
    methods:{

        // this methos is created for open profile popup 
        openPopup: function () {
            this.profilePopup = true;
            this.$emit('profilePopup', this.profilePopup);
        },

        // this method is created for get category
        getCategory(item){
            if(item === 'SugarBaby(M)NOTIFICATION'){
                var category = 1
            }else if(item === 'SugarBaby(F)NOTIFICATION'){
                 category = 2
            }else if(item === 'SugarDadNOTIFICATION'){
                category = 3
            }else if(item === 'CougarNOTIFICATION'){
                 category = 4
            }else if(item === 'ToyBoy(M)NOTIFICATION'){
                 category = 5
            }else{
                category = 1
            }

            return category;
        },

        // this method is created for get category message 
        getCategoryTitle(item){
            if(item === 'SugarBaby(M)NOTIFICATION'){
                var category = 'Hvad venter du på ? Din egen Sugarbabe ?'
            }else if(item === 'SugarBaby(F)NOTIFICATION'){
                 category = 'Hvad kigger du efter ? En Sugarbabe ?'
            }else if(item === 'SugarDadNOTIFICATION'){
                category = 'Hvad søger du ? En Sugardad ?'
            }else if(item === 'CougarNOTIFICATION'){
                 category = 'Hvad drømmer du om ? Din egen Cougar ?'
            }else if(item === 'ToyBoy(M)NOTIFICATION'){
                 category = 'Hvem leder du efter ? Din egen Toyboy ?'
            }

            return category;
        }
    }

}
</script>

<style>

</style>