<template>
  <div class="home">
     <div class="main flood">
       <!-- carousel -->
        <div class="container">
          <div v-if="loading" class="loader">
          </div>

          <div v-else class="flood-bg">
            <!-- banner -->
          
            <div id="demo" v-if="slidelist" class="carousel carousel-fade slide" data-ride="carousel">
              <div class="carousel-inner">
                <div :class=" i === 0 ? 'active' : ''" class="carousel-item" v-for="(item, i) in slidelist" :key="'a'+i" :id="'slide-'+i">
                  <a :href="item.link_path">
                    <vue-load-image>
                        <img slot="image" :src="baseURL+item.image"  :alt="item.title">
                        <div slot="preloader" class="hart-loader"></div>
                        <div slot="error"></div>
                    </vue-load-image>
                  </a>
                </div>
              </div>
              <div class="carousel_nav">
                <ul class="carousel-indicators">
                  <li v-for="(item, k) in slidelist" :key="'e'+k" data-target="#demo" :data-slide-to="k" :class="{ 'active': k === 0 }">{{k + 1}}</li>
                  <li>af {{slidelist.length}}</li>
                </ul>
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                  <!-- <span class="carousel-control-prev-icon"></span> -->
                  <img src="./../assets/images/arrow.png" class="prev" alt="prev">
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                  <!-- <span class="carousel-control-next-icon"></span> -->
                  <img src="./../assets/images/arrow.png" class="next" alt="next">
                </a>
              </div>
            </div>
            <div v-else class="hart-loader"></div>

            <!-- post image section  -->
          <div v-if="imagelist" class="pt-4 pb-4">
            <div class="row m-0">
              <div class="col-md-2 col-sm-4 col-4 pl-0 pr-0" v-for="(item, j) in imagelist" :key="'b'+j" :id="'image-'+j">
                <div class="below-slider-image">
                  <a :href="item.link_path">
                    <img :src="baseURL+item.image" class="img-fluid"  :alt="item.title">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="hart-loader"></div>

          <div class="post-list">
            
            <div  v-for="(item, d) in posts" :key="'c'+d" :id="'post_id_'+d+1" class="pl-3 pr-3">
              <!-- image post  -->
              <Post page="flood" v-if="item.postType === 'IMAGE' && item.image_status === '1'" :item="item" :logedinUser="logedinUser" :userMembership="userMembership" :postIds="'post-'+d" />
              <Post page="flood" v-else-if="item.postType === 'IMAGE' && item.userId === logedinUser" :item="item" :logedinUser="logedinUser" :userMembership="userMembership" :postIds="'post-'+d" />
              
              <!-- created post by admin  -->
              <AdminPost page="flood" v-else-if="item.postType === 'ADMIN'" :item="item" :userMembership="userMembership" :logedinUser="logedinUser" :postIds="'post-'+d" />
              
              <!-- Date Opsalag  -->
              <DateStorage page="flood" v-else-if="item.postType === 'INVITATION'" :item="item" :userMembership="userMembership" :logedinUser="logedinUser" :feedIds="'post-'+d" />
            
              <!-- Post Gallery Notification  -->
              <AdminMessage page="flood" :logedinUser="logedinUser" v-else-if="item.postType === 'GALLERYNOTIFICATION' || item.postType === 'SugarBaby(M)NOTIFICATION' || item.postType === 'SugarBaby(F)NOTIFICATION' || item.postType === 'SugarDadNOTIFICATION' || item.postType === 'ToyBoy(M)NOTIFICATION' || item.postType === 'CougarNOTIFICATION'" :item="item" :userMembership="userMembership"  @profilePopup="profilePopup = $event" />
              
              <!-- New Signup  -->
              <NewSignup page="flood" v-else-if="item.postType === 'NEWSIGNUP'" :item="item" :logedinUser="logedinUser" :userMembership="userMembership" :postIds="'post-'+d" />
              
              <!-- News Feed  -->
              <Feed page="flood" v-else-if="item.postType === 'NEWSFEED'" :item="item" :logedinUser="logedinUser" :userMembership="userMembership" :postIds="'post-'+d" />

            </div>
          </div>
          <div v-if="loader === true && totalCount != posts.length" class="text-center p-3">
            <img src="./../assets/images/heart.svg" alt="loader">
          </div>

          <!-- Modal -->
          <div class="modal fade"  id="silverImagePost" tabindex="-1" aria-hidden="true">
              <div class="modal-small modal-dialog modal-dialog-centered">
                  <div class="modal-content dark-popup">
                      <div class="modal-content-inner">
                          <div class="modal-header blank-header">
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                                  <p class="text-center mb-0 font-weight-400" id="silvermemberLabel">
                                      <a class="" :href="baseURL+'user/my_membership?planid=1'" target="_blank"><u>Få din egen Guldnøgle og et <br>frækt forspring !</u></a>
                                  </p>
                              </div>
                          <div class="modal-footer">
                          
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div id="user-profile" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div role="document" class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-content-inner">
                  <div class="modal-header">
                    <h5 class="modal-title">Nyeste Billeder</h5>
                    <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body pr-1">
                    <div class="user-profiles" ref="profileContainer">
                      <!-- gold membership url  -->
                      <div v-if="userMembership != '2'" class="col-12 text-center login-url"><p>Har du Gæstekort ?
                        <a :href="baseURL+'user/my_membership?planid=1'" target="_blank">Hent Guldnøglen hér, og lås alle billederne op.</a></p>
                      </div>
                      <vue-custom-scrollbar class="pr-0 scroll-area"  :settings="settings" @ps-y-reach-end="richEndonPopup()">
                        <div class="row mr-0 pr-2">
                          <div v-if="profilePopup === true"></div>
                          <div v-for="(item, l) in userImages" :key="'d'+l" :class="userMembership === '2' ? '' : 'img-blur'" class="col-lg-3 col-md-4 col-sm-4 col-6 p-1">
                            <div class="img-wrapper"
                            v-tooltip.mouseTrail="{
                                    content: '<span>'+item.name+', '+item.age+' år</span> <a style=background-image:url('+icons+') class='+getLargIcon(item.play)+'></a><a style=background-image:url('+icons+') class='+getSmallIcon(item.play)+'></a> ',
                                    classes: 'tooltipClasses',
                                    loadingContent: 'Please wait...',
                                    loadingClass: 'content-is-loading',
                                  }"
                            >
                            <a :href="item.profile_link" target="_blank">
                              <!-- <img alt="img" class="img-fluid" :src="baseURL+item.image" > -->
                              <vue-load-image>
                                  <img slot="image" class="img-fluid" :src="baseURL+item.image"  :alt="item.title">
                                  <div slot="preloader" class="hart-loader"></div>
                                  <div slot="error"></div>
                                  <span slot="image"></span>
                              </vue-load-image>
                            </a>
                            </div>
                          </div>
                          <div v-if="loader === true && profileTotalCount != userImages.length" class="text-center p-2 col-md-12">
                            <img src="./../assets/images/heart.svg" class="loader-lg" alt="loader">
                          </div>
                        </div>
                      </vue-custom-scrollbar>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Icons from './../assets/images/icons.png';
import axios from 'axios';
import NewSignup from './../components/NewSignup';
import Feed from './../components/Feed';
import DateStorage from './../components/DateStorage';
import Post from './../components/Post';
import AdminPost from './../components/AdminPost';
import AdminMessage from './../components/AdminMessage';
import EventBus from './../bus';
import VueLoadImage from 'vue-load-image';

import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  name: 'Home',
  components: {
    NewSignup,
    DateStorage,
    Post,
    AdminPost,
    Feed,
    AdminMessage,
    vueCustomScrollbar,
    'vue-load-image': VueLoadImage,
  },
  data(){
    return{
      slidelist: [],
      imagelist:[],
      posts: [],
      icons :  Icons,
      loading: true,
      loader: true,
      userMembership: '',
      feedPageNo: '1',
      pageCount: '1',
      totalCount: "",
      baseURL: process.env.VUE_APP_BASE_URL,
      userImages: [ ],
      loadPopup: '1',
      profilePopup: false,
      profilePageCount: '',
      profileTotalCount: '',
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
        reachY : 'end',
        onLine: navigator.onLine ? true : false,
      },
      enableLoadData: true,
      enableGetFeedList: true,
      accessToken: ''
    }
  },
  created() {
    EventBus.$on('logedinUser', this.getLogedinUser);
    EventBus.$on('userMembership', this.getMembership);

    this.accessToken = JSON.parse(this.$session.get('token'));
    this.getUserProfiles();
  },
  mounted() {
   // Detect when scrolled to bottom.
    const listElm = document.querySelector('.page');
     listElm.addEventListener('scroll', () => {
       if(Math.ceil(listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight && this.enableLoadData) {
        this.richEnd();
        this.enableLoadData = false;
      }
     });

   
  },
   methods: {

     // this method is created for get large category icon
     getLargIcon: function(id){
            if(id==1){
               var largeIcon='large_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='large_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='large_icon-sugar-dad';
            }else if(id==4){
                largeIcon='large_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='large_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

      // this method is created for get small category icon
      getSmallIcon: function(id){
          if(id==1){
              var largeIcon='small_icon-sugar-baby-male'; 
          }else if(id == 2){
              largeIcon='small_icon-sugar-baby-female';              
          }else if(id==3){
              largeIcon='small_icon-sugar-dad';
          }else if(id==4){
              largeIcon='small_icon-sugar-mom';              
          }else if(id==5){
              largeIcon='small_icon-toy-boys-mand';              
          }
          return largeIcon;  
      },

    // this method is created for load more popup data
    richEndonPopup() {
      if(this.profilePopup === true){
        var i = this.loadPopup;
        if(i < this.profilePageCount){
          this.loadPopup = ++i;
          this.profilePopup = false;
          this.loadUserProfiles();
          this.loader = true;
        }else{
          this.loader = false;
        } 
      }
    },

    // this method is created for load more post
    richEnd() {
          var i = this.feedPageNo;
          if(i < this.pageCount){
              this.feedPageNo = ++i;
              
              this.loadFeedList();
          }
    },

    // this method is created for get users post image
    getUserProfiles: function(){  
      let formData = new FormData();
      formData.append('pageNo', 1);
      
      axios.post('getUserGalleryAndImage', formData).then(response => {
        const data = response.data;
        this.userImages = data.result.data;
        this.profilePageCount = data.page_count;
        this.profileTotalCount = data.total_count;
        this.loader = false;
        }).finally(() => (this.loading = false));
    },

     // this method is created for load users post image
    loadUserProfiles: function(){
      let formData = new FormData();
      formData.append('pageNo', this.loadPopup);
      
      axios.post('getUserGalleryAndImage', formData).then(response => {
        const data = response.data;

        this.userImages = this.userImages.concat(data.result.data);
        this.profilePopup = true;
        this.loader = false;
        }).finally(() => (this.loading = false));
    },

     // this method is created for get logedin user
     getLogedinUser(value) {
       
        this.logedinUser = value;  
        if(this.enableGetFeedList === true){
          this.enableGetFeedList = false;
          this.getFeedList();
        }
        
      },

       // this method is created for get feed list
      getFeedList: function(){
       let API = 'getFeedList?userId='+this.accessToken+'&pageNo='+this.feedPageNo;
        
        axios.get(API).then(response => (
            this.slidelist = response.data.result.sliderImages,
            this.imagelist = response.data.result.bannerImages,
            this.posts = response.data.result.users,
            this.pageCount = response.data.page_count,
            this.totalCount = response.data.total_count,
            this.loader = false,
            this.enableGetFeedList = false
          )).catch(error => {
            this.errorMessage = error;
            console.error("There was an error!", error);
          }).finally(() => (this.loading = false));
      },

       // this method is created for load feed list
      loadFeedList: function(){
        this.loader = true;
        let API = 'getFeedList?userId='+this.accessToken+'&pageNo='+this.feedPageNo;
        
        axios.get(API).then(response => (
            this.posts = this.posts.concat(response.data.result.users),
            this.enableLoadData = true,
            this.loader = false
          )).finally(() => (this.loading = false));
        
      },

    // this method is created for get membership
    getMembership(value) {
      this.userMembership = value;
    },

    // this method is created for update page router change
    // yourCallBackFunction () {
    //     window.location.reload()
    // },
    
  },
  //  beforeRouteLeave(to, from, next) {
  //       this.yourCallBackFunction();
  //       return next();   
  //   }
}
</script>