<template>
    
    <div class="start-post" :id="feedIds">
        <div class="post-header">
            <div class="row align-items-center">
                <div class="col-sm-5">
                    <div class="d-flex align-items-center">
                        <a target="_blank" :class="userMembership === '2' ? '' : 'img-blur'" class="profile-sm img-wrap" :href="item.profile_link">
                            <img :src="item.avatar" alt="Profile" class="profile-sm">
                        </a>
                        <div class="admin-name pl-1">
                            <h5 class="m-0 text-white"><a target="_blank" :href="item.profile_link">{{item.name}}</a></h5>
                            <p class="m-0">{{convertUTC(item.flood_created_date)}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7 d-none d-md-block">
                    <div class="d-flex justify-content-end align-items-center text-white">
                        <div v-if="item.request_status != '0' && item.remaining_seconds_to_expire != '0'">
                            <button v-if="userMembership === '2' && logedinUser != item.userId" v-on:click="applyInvitations(item.invitation_id)" :disabled='isDisabled' type="button" class="btn btn-primary btn-md">
                            Interesseret?
                            </button> 
                            <span v-else-if="logedinUser === item.userId" v-tooltip="'Du kan ikke sende Dateanmodning til dig selv'">
                                <button  type="button" class="btn btn-light btn-md" disabled >
                                Interesseret?
                                </button>
                            </span>
                            <button v-else type="button" class="btn btn-primary btn-md" data-toggle="modal" data-target="#silverImagePost">
                            Interesseret?
                            </button>
                        </div>
                        <div v-else class="status">
                            <ul v-if="item.remaining_seconds_to_expire === '0'" class="pl-3 mb-1 d-none d-md-block float-right">
                                <li>Udløbet</li>
                            </ul>
                             <span v-else :class="item.request_status === '0' ? 'applied text-success' : 'notapplied'">
                                <span style="--i:1">D</span>
                                <span style="--i:2">i</span>
                                <span style="--i:3">t</span>  <span style="--i:5">ø</span>
                                <span style="--i:6">n</span>
                                <span style="--i:7">s</span>
                                <span style="--i:8">k</span>
                                <span style="--i:9">e</span> <span style="--i:10">e</span>
                                <span style="--i:11">r</span> <span style="--i:12">s</span>
                                <span style="--i:13">e</span>
                                <span style="--i:14">n</span>
                                <span style="--i:15">d</span>
                                <span style="--i:16">t</span>
                             </span>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="feed-content">
            <div class="row align-items-top">
                <div class="col-md-12 col-lg-12 text-center">
                   <ul v-if="item.remaining_seconds_to_expire === '0'" class="pl-3 mb-1 d-block d-md-none status">
                        <li>Udløbet</li>
                    </ul>
                    <h5 class="text-white mb-2"><span class="badge  badge-pill text-danger bg-light">Date Opslag</span></h5>
                    <p v-if="item.dating_description !== null" @click="showMore(item.invitation_id)" :class="item.dating_description.length > textlength ? 'cursor-pointer mb-0' : 'mb-0'">Alder: {{item.age}} år, Højde: {{item.height}} cm, Vægt: {{item.weight}} kg, Postnr: {{item.code}}, By: {{item.city}}</p>
                    
                </div>
                <!-- <div class="col-md-4 col-lg-3 d-none d-md-block text-left text-md-right mt-1 ">
                    
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-12 datopslag-cont">
                    <h5 @click="showMore(item.invitation_id)" :class="item.dating_description.length > textlength ? 'cursor-pointer' : ''" class="pt-2 mb-0 pb-1 text-white">{{item.title}}</h5>
                    <div @click="showMore(item.invitation_id)" :class="item.dating_description.length > textlength ? 'cursor-pointer' : ''" class="post-description less mb-0" v-if="!readMore[item.invitation_id]">
                        {{item.dating_description.length > textlength ? item.dating_description.substring(0, textlength) + "..." : item.dating_description}}
                        <a v-if="!readMore[item.invitation_id]" :class="item.dating_description.length > textlength ? '' : 'd-none'" class="more-link">Se mere</a>
                    
                    </div>
                    <div @click="showMore(item.invitation_id)" :class="item.dating_description.length > textlength ? 'cursor-pointer ' : 'post-description full mb-0 '" v-if="readMore[item.invitation_id]">
                        {{item.dating_description}}
                        <a v-if="readMore[item.invitation_id]" :class="item.dating_description.length > textlength ? '' : 'd-none'" class="more-link ">Se mindre</a>
                    </div>
                    <vue-countdown-timer v-if="item.remaining_seconds_to_expire != '0'" class=" timer mt-3 mb-0 "
                        @end_callback="endCallBack('event ended')"
                        :start-time="myDate(item.time) | formatTimer"
                        :end-time="myDate(item.end_date) | formatTimer"
                        :interval="1000"
                        label-position="begin"
                        :end-text="'Udløbet'"
                        :day-txt="' dag(e)'"
                        :hour-txt="' time(r)'"
                        :minutes-txt="' minut(ter)'"
                        :seconds-txt="' sekund(er)'">
                        <template slot="countdown" slot-scope="scope">
                            <label><span class="days">{{scope.props.days}}</span>{{scope.props.dayTxt}}</label>
                            <label><span class="hours">{{scope.props.hours}}</span>{{scope.props.hourTxt}}</label>
                            <label><span class="minuts">{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}</label>
                            <label><span class="seconds">{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}</label>
                        </template>
                    </vue-countdown-timer>
                    <div v-if="item.remaining_seconds_to_expire != '0'" class="d-block d-md-none text-center mt-3">
                        <div v-if="item.request_status != '0' && item.remaining_seconds_to_expire != '0'">
                            <button v-if="userMembership === '2' && logedinUser != item.userId" v-on:click="applyInvitations(item.invitation_id)" :disabled='isDisabled' type="button" class="btn btn-primary btn-md">
                            Interesseret?
                            </button> 
                            <span v-else-if="logedinUser === item.userId" v-tooltip="'Du kan ikke sende Dateanmodning til dig selv'">
                                <button  type="button" class="btn btn-light btn-md" disabled >
                                Interesseret?
                                </button>
                            </span>
                            <button v-else type="button" class="btn btn-primary btn-md" data-toggle="modal" data-target="#silverImagePost">
                            Interesseret?
                            </button>
                        </div>
                        <div v-else class="status">
                            <span :class="item.request_status === '0' ? 'applied text-success' : 'notapplied'">
                                <span style="--i:1">D</span>
                                <span style="--i:2">i</span>
                                <span style="--i:3">t</span>  <span style="--i:5">ø</span>
                                <span style="--i:6">n</span>
                                <span style="--i:7">s</span>
                                <span style="--i:8">k</span>
                                <span style="--i:9">e</span> <span style="--i:10">e</span>
                                <span style="--i:11">r</span> <span style="--i:12">s</span>
                                <span style="--i:13">e</span>
                                <span style="--i:14">n</span>
                                <span style="--i:15">d</span>
                                <span style="--i:16">t</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    
</template>

<script>
import LongPress from 'vue-directive-long-press';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'DateStorage',
  components: {
  },
    directives: {
        'long-press': LongPress
    },
  props: {
        feedIds: String,
        logedinUser: String,
        userMembership: String,
        item: Object,
        page: String
    },
    data: () => ({
        baseURL: process.env.VUE_APP_BASE_URL,
        showModal: false,
        readMore: {},
        textlength: 70,
        isDisabled : false,
        accessToken: ''
    }),
    beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
    methods: {
        // this methos is created for connvert date on UTC format
        convertUTC: function(time){
            let local = moment.utc(time).local().format('DD-MM-YYYY hh:mm');
            return local;
        },

        // this methos is created for show more text
        showMore(id) {
            if(this.readMore[id] === true){
                this.$set(this.readMore, id, false);
            }else{
                this.$set(this.readMore, id, true);
            }
        },

        // this methos is created for timer refresh
        endCallBack: function() {
            window.location.reload()
        },

        // getType:function(){
        //     if(this.logedinUser === this.item.userId){
        //         var Type = 2;
        //     }else{
        //         Type = 1;
        //     }
        //     return Type
        // },

        // this methos is created for get dateusing timestamp
        myDate: function(val){
            let timestamp = { seconds: val, nanoseconds: 0 }     
            return new Date(timestamp.seconds * 1000)
        },

        // this methos is created for apply invitation
        applyInvitations: function(invitation_id){
             this.isDisabled = true;
            this.$set(this.item, 'request_status', '0')
            let applyAction = new FormData();
            applyAction.append('invitationId', invitation_id);
            applyAction.append('userId', this.accessToken);
                
            axios.post('applyPublicInvitations', applyAction).then(response => {
                const data = response.data;
                if(data.message === 'Success'){
                    this.showModal = false;
                }
            }, (error) => {
                    console.log('error: ',error);
                    this.isDisabled = false;
            }); 
        }
    }
}
</script>