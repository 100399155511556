<template>
  <div class="container">
      <div class="breadcrumb text-center ml-3 mr-3">
          <h1><span>Mit Feed <img src="./../assets/images/headingicon.png"></span></h1>
          <div class="breadcrumb-url">
              <a :href="baseURL+'user/owner.html'">Mit Sted</a>><span>Mit Feed</span>
          </div>
      </div>
      <!-- <Post callback="#" postIds="post-1" author="Admin" isPublished="19-01-2021, 08:45" title="Byd varmt velkommen og sig hej til" name="Drake Donald" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
      <Post callback="#" postIds="post-2" author="Mac Donald" isPublished="19-01-2021, 08:45" title="Byd varmt velkommen og sig hej til" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry." /> -->
      <div v-if="loading" class="loader">
      </div>
      <div v-else>
        <div class="my-post-bg pt-3 pl-3 pb-3 pr-3 text-center text-light" v-if="!myFeed || !myFeed.length ">
          Intet nyt lige nu. Prøv igen senere.
        </div>
        <div v-else class="my-post-bg pt-2 pl-3 pb-3 pr-3 text-white">
          <div v-for="(item, e) in myFeed" :key="e">
            <Post page="myFeed" v-if="item.postType === 'IMAGE'" :logedinUser="logedinUser" :item="item" :userMembership="userMembership"  :postIds="'post-'+e" />
            <!-- New Signup  -->
            <NewSignup page="myFeed" v-else-if="item.postType === 'NEWSIGNUP'" :item="item" :logedinUser="logedinUser" :userMembership="userMembership" :postIds="'post-'+e" />
          
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import axios from 'axios';
import NewSignup from './../components/NewSignup';
import EventBus from './../bus';
import Post from './../components/Post';

export default {
  name: 'MyFeed',
  components: {
    NewSignup,
    // NewsFeed,
    Post
  },
  data(){
    return{
      logedInUser:'',
      myFeed: [],
      loading: true,
      baseURL: process.env.VUE_APP_BASE_URL,
      // UserID: this.$route.params.userID,
      // membership: this.$route.params.membership
      accessToken: '',
      feedPageNo: '1',
      pageCount: '1'
    }
  },
  beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
  mounted() { 
    EventBus.$on('logedinUser', this.getLogedinUser)
    EventBus.$on('userMembership', this.getMembership)

    const listElm = document.querySelector('.page');
     listElm.addEventListener('scroll', () => {
       if(Math.ceil(listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight && this.enableLoadData) {
        this.richEnd();
        this.enableLoadData = false;
      }
     });
     
  },
   methods: {
     // this method is created for load more post
    richEnd() {
          var i = this.feedPageNo;
          if(i < this.pageCount){
              this.feedPageNo = ++i;
              this.loadFeedList();
          }
    },

     // this method is created for get logedin user
     getLogedinUser(value) {
          this.logedinUser = value;
          this.getMyFeed();
      },

      // this method is created for get user membership
      getMembership(value) {
          this.userMembership = value;
      },
      // myDate: function(val){
      //   let timestamp = { seconds: val, nanoseconds: 0 }     
      //   return new Date(timestamp.seconds * 1000)
      // },

      // this method is created for get myfeed data
      getMyFeed: function(){
        let API = 'getMyFeedList?userId='+this.accessToken+'&pageNo='+this.feedPageNo;
          
        axios.get(API).then(response => (
            this.myFeed = response.data.result.MyFeed,
            this.pageCount = response.data.result.page_count,
            this.totalCount = response.data.result.total_count,
            console.log(response.data.result)
          )).finally(() => (this.loading = false));
      },

       // this method is created for load feed list
      loadFeedList: function(){
        let API = 'getMyFeedList?userId='+this.accessToken+'&pageNo='+this.feedPageNo;
        
        axios.get(API).then(response => (
            this.enableLoadData = true,
            this.myFeed = response.data.result.MyFeed
          )).finally(() => (this.loading = false));
        
      },

      // this method is created for get date
    myDdate: function(val){
      let timestamp = { seconds: val, nanoseconds: 0 }     
      return new Date(timestamp.seconds * 1000)
    },
  }
  
}
</script>
