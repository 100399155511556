export const Category = [
    {id: "1", name: "Sugar Baby (M)", slug: 'sugar-babies-m'},
    {id: "2", name: "Sugar Baby (F)" , slug: 'sugar-babies-f'},
    {id: "3", name: "Sugar Dad", slug: 'sugar-dads'},
    {id: "4", name: "Cougar", slug: 'cougar'},
    {id: "5", name: "Toy Boy", slug: 'toy-boy'},
];

export const Messages = {
  blankEmail : 'Skriv venligst din email.',
  wrongEmail : 'Indtast en gyldig email.',
  blankPassword : 'Skriv venligst din Adgangskode.',
  wrongPassword : 'Skriv min. 6 tegn.',
  noAccount : 'Beklager, vi genkender ikke email eller Adgangskode. Prøv igen.',
  blankName : 'Vælg et profilnavn.',
  wrongName : 'Kun bogstaver tilladt.',
  writeEmail : 'Indtast venligst din email.',
  writePassword : 'Vælg en Adgangskode.',
  blankAge : 'Vælg venligst alder.',
  blankConfirmPassword : 'Gentag Adgangskode.',
  differentPassword: 'Skriv adgangskode.'
};

export const Height = [
    { value: '140', text: '140 cm' },
    { value: '141', text: '141 cm' },
    { value: '142', text: '142 cm' },
    { value: '143', text: '143 cm' },
    { value: '144', text: '144 cm' },
    { value: '145', text: '145 cm' },
    { value: '146', text: '146 cm' },
    { value: '147', text: '147 cm' },
    { value: '148', text: '148 cm' },
    { value: '149', text: '149 cm' },
    { value: '150', text: '150 cm' },
    { value: '151', text: '151 cm' },
    { value: '152', text: '152 cm' },
    { value: '153', text: '153 cm' },
    { value: '154', text: '154 cm' },
    { value: '155', text: '155 cm' },
    { value: '156', text: '156 cm' },
    { value: '157', text: '157 cm' },
    { value: '158', text: '158 cm' },
    { value: '159', text: '159 cm' },
    { value: '160', text: '160 cm' },
    { value: '161', text: '161 cm' },
    { value: '162', text: '162 cm' },
    { value: '163', text: '163 cm' },
    { value: '164', text: '164 cm' },
    { value: '165', text: '165 cm' },
    { value: '166', text: '166 cm' },
    { value: '167', text: '167 cm' },
    { value: '168', text: '168 cm' },
    { value: '169', text: '169 cm' },
    { value: '170', text: '170 cm' },
    { value: '171', text: '171 cm' },
    { value: '172', text: '172 cm' },
    { value: '173', text: '173 cm' },
    { value: '174', text: '174 cm' },
    { value: '175', text: '175 cm' },
    { value: '176', text: '176 cm' },
    { value: '177', text: '177 cm' },
    { value: '178', text: '178 cm' },
    { value: '179', text: '179 cm' },
    { value: '180', text: '180 cm' },
    { value: '181', text: '181 cm' },
    { value: '182', text: '182 cm' },
    { value: '183', text: '183 cm' },
    { value: '184', text: '184 cm' },
    { value: '185', text: '185 cm' },
    { value: '186', text: '186 cm' },
    { value: '187', text: '187 cm' },
    { value: '188', text: '188 cm' },
    { value: '189', text: '189 cm' },
    { value: '190', text: '190 cm' },
    { value: '191', text: '191 cm' },
    { value: '192', text: '192 cm' },
    { value: '193', text: '193 cm' },
    { value: '194', text: '194 cm' },
    { value: '195', text: '195 cm' },
    { value: '196', text: '196 cm' },
    { value: '197', text: '197 cm' },
    { value: '198', text: '198 cm' },
    { value: '199', text: '199 cm' },
    { value: '200', text: '200 cm' },
    { value: '201', text: '201 cm' },
    { value: '202', text: '202 cm' },
    { value: '203', text: '203 cm' },
    { value: '204', text: '204 cm' },
    { value: '205', text: '205 cm' },
    { value: '206', text: '206 cm' },
    { value: '207', text: '207 cm' },
    { value: '208', text: '208 cm' },
    { value: '209', text: '209 cm' },
    { value: '210', text: '210 cm' },
    { value: '211', text: '211 cm' },
    { value: '212', text: '212 cm' },
    { value: '213', text: '213 cm' },
    { value: '214', text: '214 cm' },
    { value: '215', text: '215 cm' },
    { value: '216', text: '216 cm' },
    { value: '217', text: '217 cm' },
    { value: '218', text: '218 cm' },
    { value: '219', text: '219 cm' },
    { value: '220', text: '220 cm' },
    { value: '221', text: '221 cm' },
    { value: '222', text: '222 cm' },
    { value: '223', text: '223 cm' },
    { value: '224', text: '224 cm' },
    { value: '225', text: '225 cm' },
    { value: '226', text: '226 cm' },
    { value: '227', text: '227 cm' },
    { value: '228', text: '228 cm' },
    { value: '229', text: '229 cm' },
    { value: '230', text: '230 cm' },
    { value: '231', text: '231 cm' },
    { value: '232', text: '232 cm' },
    { value: '233', text: '233 cm' },
    { value: '234', text: '234 cm' },
    { value: '235', text: '235 cm' },
    { value: '236', text: '236 cm' },
    { value: '237', text: '237 cm' },
    { value: '238', text: '238 cm' },
    { value: '239', text: '239 cm' },
    { value: '240', text: '240 cm' },
    { value: '241', text: '241 cm' },
    { value: '242', text: '242 cm' },
    { value: '243', text: '243 cm' },
    { value: '244', text: '244 cm' },
    { value: '245', text: '245 cm' },
    { value: '246', text: '246 cm' },
    { value: '247', text: '247 cm' },
    { value: '248', text: '248 cm' },
    { value: '249', text: '249 cm' },
    { value: '250', text: '250 cm' },
  ];

  export const Weight = [
    { value: '40', text: '40 kg' },
    { value: '41', text: '41 kg' },
    { value: '42', text: '42 kg' },
    { value: '43', text: '43 kg' },
    { value: '44', text: '44 kg' },
    { value: '45', text: '45 kg' },
    { value: '46', text: '46 kg' },
    { value: '47', text: '47 kg' },
    { value: '48', text: '48 kg' },
    { value: '49', text: '49 kg' },
    { value: '50', text: '50 kg' },
    { value: '51', text: '51 kg' },
    { value: '52', text: '52 kg' },
    { value: '53', text: '53 kg' },
    { value: '54', text: '54 kg' },
    { value: '55', text: '55 kg' },
    { value: '56', text: '56 kg' },
    { value: '57', text: '57 kg' },
    { value: '58', text: '58 kg' },
    { value: '59', text: '59 kg' },
    { value: '60', text: '60 kg' },
    { value: '61', text: '61 kg' },
    { value: '62', text: '62 kg' },
    { value: '63', text: '63 kg' },
    { value: '64', text: '64 kg' },
    { value: '65', text: '65 kg' },
    { value: '66', text: '66 kg' },
    { value: '67', text: '67 kg' },
    { value: '68', text: '68 kg' },
    { value: '69', text: '69 kg' },
    { value: '70', text: '70 kg' },
    { value: '71', text: '71 kg' },
    { value: '72', text: '72 kg' },
    { value: '73', text: '73 kg' },
    { value: '74', text: '74 kg' },
    { value: '75', text: '75 kg' },
    { value: '76', text: '76 kg' },
    { value: '77', text: '77 kg' },
    { value: '78', text: '78 kg' },
    { value: '79', text: '79 kg' },
    { value: '80', text: '80 kg' },
    { value: '81', text: '81 kg' },
    { value: '82', text: '82 kg' },
    { value: '83', text: '83 kg' },
    { value: '84', text: '84 kg' },
    { value: '85', text: '85 kg' },
    { value: '86', text: '86 kg' },
    { value: '87', text: '87 kg' },
    { value: '88', text: '88 kg' },
    { value: '89', text: '89 kg' },
    { value: '90', text: '90 kg' },
    { value: '91', text: '91 kg' },
    { value: '92', text: '92 kg' },
    { value: '93', text: '93 kg' },
    { value: '94', text: '94 kg' },
    { value: '95', text: '95 kg' },
    { value: '96', text: '96 kg' },
    { value: '97', text: '97 kg' },
    { value: '98', text: '98 kg' },
    { value: '99', text: '99 kg' },
    { value: '100', text: '100 kg' },
    { value: '101', text: '101 kg' },
    { value: '102', text: '102 kg' },
    { value: '103', text: '103 kg' },
    { value: '104', text: '104 kg' },
    { value: '105', text: '105 kg' },
    { value: '106', text: '106 kg' },
    { value: '107', text: '107 kg' },
    { value: '108', text: '108 kg' },
    { value: '109', text: '109 kg' },
    { value: '110', text: '110 kg' },
    { value: '111', text: '111 kg' },
    { value: '112', text: '112 kg' },
    { value: '113', text: '113 kg' },
    { value: '114', text: '114 kg' },
    { value: '115', text: '115 kg' },
    { value: '116', text: '116 kg' },
    { value: '117', text: '117 kg' },
    { value: '118', text: '118 kg' },
    { value: '119', text: '119 kg' },
    { value: '120', text: '120 kg' },
    { value: '121', text: '121 kg' },
    { value: '122', text: '122 kg' },
    { value: '123', text: '123 kg' },
    { value: '124', text: '124 kg' },
    { value: '125', text: '125 kg' },
    { value: '126', text: '126 kg' },
    { value: '127', text: '127 kg' },
    { value: '128', text: '128 kg' },
    { value: '129', text: '129 kg' },
    { value: '130', text: '130 kg' },
    { value: '131', text: '131 kg' },
    { value: '132', text: '132 kg' },
    { value: '133', text: '133 kg' },
    { value: '134', text: '134 kg' },
    { value: '135', text: '135 kg' },
    { value: '136', text: '136 kg' },
    { value: '137', text: '137 kg' },
    { value: '138', text: '138 kg' },
    { value: '139', text: '139 kg' },
    { value: '140', text: '140 kg' },
    { value: '141', text: '141 kg' },
    { value: '142', text: '142 kg' },
    { value: '143', text: '143 kg' },
    { value: '144', text: '144 kg' },
    { value: '145', text: '145 kg' },
    { value: '146', text: '146 kg' },
    { value: '147', text: '147 kg' },
    { value: '148', text: '148 kg' },
    { value: '149', text: '149 kg' },
    { value: '150', text: '150 kg' },
  ];

export const Age = [
    { value: '18', text: '18 år' },
    { value: '19', text: '19 år' },
    { value: '20', text: '20 år' },
    { value: '21', text: '21 år' },
    { value: '22', text: '22 år' },
    { value: '23', text: '23 år' },
    { value: '24', text: '24 år' },
    { value: '25', text: '25 år' },
    { value: '26', text: '26 år' },
    { value: '27', text: '27 år' },
    { value: '28', text: '28 år' },
    { value: '29', text: '29 år' },
    { value: '30', text: '30 år' },
    { value: '31', text: '31 år' },
    { value: '32', text: '32 år' },
    { value: '33', text: '33 år' },
    { value: '34', text: '34 år' },
    { value: '35', text: '35 år' },
    { value: '36', text: '36 år' },
    { value: '37', text: '37 år' },
    { value: '38', text: '38 år' },
    { value: '39', text: '39 år' },
    { value: '40', text: '40 år' },
    { value: '41', text: '41 år' },
    { value: '42', text: '42 år' },
    { value: '43', text: '43 år' },
    { value: '44', text: '44 år' },
    { value: '45', text: '45 år' },
    { value: '46', text: '46 år' },
    { value: '47', text: '47 år' },
    { value: '48', text: '48 år' },
    { value: '49', text: '49 år' },
    { value: '50', text: '50 år' },
    { value: '51', text: '51 år' },
    { value: '52', text: '52 år' },
    { value: '53', text: '53 år' },
    { value: '54', text: '54 år' },
    { value: '55', text: '55 år' },
    { value: '56', text: '56 år' },
    { value: '57', text: '57 år' },
    { value: '58', text: '58 år' },
    { value: '59', text: '59 år' },
    { value: '60', text: '60 år' },
    { value: '61', text: '61 år' },
    { value: '62', text: '62 år' },
    { value: '63', text: '63 år' },
    { value: '64', text: '64 år' },
    { value: '65', text: '65 år' },
    { value: '66', text: '66 år' },
    { value: '67', text: '67 år' },
    { value: '68', text: '68 år' },
    { value: '69', text: '69 år' },
    { value: '70', text: '70 år' },
    { value: '71', text: '71 år' },
    { value: '72', text: '72 år' },
    { value: '73', text: '73 år' },
    { value: '74', text: '74 år' },
    { value: '75', text: '75 år' },
    { value: '76', text: '76 år' },
    { value: '77', text: '77 år' },
    { value: '78', text: '78 år' },
    { value: '79', text: '79 år' },
    { value: '80', text: '80 år' },
  ];