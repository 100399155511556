<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark">
            <a class="navbar-brand" :href="baseURL+'loungen'">
            <img src="./../assets/images/logo.png" alt="Logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="right-menu">
                <div v-on:click="removeClass" class="remove-drover"></div>
                <ul class="navbar-nav ml-auto my-2 my-lg-0">
                    <li v-if="userMembership === '2'" class="text-gold d-none">
                        Dit 3 Dages Guldpas er udløbet ! 
                        <a class="text-gold" target="_blank" :href="baseURL+'user/my_membership?planid=1'"><strong><u>Køb mere Tid</u></strong></a>
                    </li>
                     <li v-bind:class=" isAddClass === true ? 'active' : ''" class="nav-item pt-2"
                    >
                        <a v-on:click="addClass" class="nav-link notification highlight" href="javascript:void(0)">
                        <i class="fa fa-bell"></i>
                        <span v-bind:class="notificationNew === 0 ? 'd-none' :'d-block'">{{notificationNew >= '99' ? '99+' : notificationNew}}</span>
                        </a>
                        <div class="notification-box">
							<div class="notification-header">
								<h4>Notifikation</h4>
								<a v-on:click="readAllNotification" :class="notificationNew === 0 ? 'disabled' :''">Markér som læst</a>
								<span v-on:click="removeClass" :class="{'myClass': isAddClass}"  class="close-notification">X</span>
							</div>
							<div v-if="!loadingNotification" class="notification-list">
                               
                               <vue-custom-scrollbar v-if="notificationList != ''" class="scroll-area" id="notificationList"  :settings="settings" @ps-y-reach-end="reachedEnd">
                                   <div v-for="item in notificationList" :key="item.id" :id="'notification-'+item.id" :class="item.read_at === '0' ? '' : 'latest'" class="notification-item">
                                        <a v-if="item.is_deleted === '0'" v-on:click="readNotification(item.dating_id, item.user_id ,item.encription_id, getType(item.type), item.is_deleted, item.id, item.user_deleted)" data-toggle="modal" data-target="#deleted-invitation">
                                            <img alt="img" v-if="item.user_deleted === '0'" src="./../assets/images/placeholder.png" class="profile-medium">
                                            <img alt="img" v-else :src="baseURL+item.avatar" class="profile-medium">
                                            <div>
                                                <p><strong v-if="item.user_deleted === '0'">{{item.d_name}} </strong> <strong v-else>{{item.name}} </strong> <span v-html="item.content"></span></p>
                                                <p><strong>{{item.title}}</strong></p>
                                                <p class="notification-time">{{item.notification_time}}</p>
                                            </div>
                                        </a>
                                        <a v-else-if="item.user_deleted === '0'" v-on:click="readNotification(item.dating_id, item.user_id, item.encription_id, getType(item.type), item.is_deleted, item.id, item.user_deleted)" data-toggle="modal" data-target="#deleted-user">
                                            <img alt="img" v-if="item.user_deleted === '0'" src="./../assets/images/placeholder.png" class="profile-medium">
                                            <img alt="img" v-else :src="baseURL+item.avatar" class="profile-medium">
                                            <div>
                                                <p><strong v-if="item.user_deleted === '0'">{{item.d_name}} </strong> <strong v-else>{{item.name}} </strong><span> <span v-html="item.content"></span></span></p>
                                                <p><strong>{{item.title}}</strong></p>
                                                <p class="notification-time">{{item.notification_time}}</p>
                                            </div>
                                        </a>
                                        <a v-else v-on:click="readNotification(item.dating_id, item.user_id, item.encription_id, getType(item.type), item.is_deleted, item.id, item.user_deleted)">
                                           <img alt="img" :src="baseURL+item.avatar" class="profile-medium">
                                            <div>
                                                <p><strong>{{item.name}}</strong> <span v-html="item.content"></span></p>
                                                <p><strong>{{item.title}}</strong></p>
                                                <p class="notification-time">{{item.notification_time}}</p>
                                            </div>
                                        </a>
                                    </div>
                               </vue-custom-scrollbar>
                               <vue-custom-scrollbar v-else class="scroll-area" id="notificationList"  :settings="settings" @ps-y-reach-end="reachedEnd">
                                   <p class="no-more-data mb-0">Ingen nye notifikationer</p>
                               </vue-custom-scrollbar>
							</div>
                            <div v-else class="notification-list">
                                <vue-custom-scrollbar class="scroll-area" id="notificationList"  :settings="settings" @ps-y-reach-end="reachedEnd">
                                   <p class="no-more-data mb-0">Loading...</p>
                               </vue-custom-scrollbar>
                            </div>
						</div>
                    </li>
                    <li v-if="user.name" class="nav-item dropdown d-flex align-items-center header-profile">
                        <a href="/user/owner.html" :title="user.name">
                            <img v-if="baseURL+user.avatar" :src="baseURL+user.avatar"  alt="Profile" class="profile-xs">
                            <span>{{user.name}}</span>
                        </a>
                        <div>
                            <a class="nav-link dropdown-toggle pb-0" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </a>
                            <div class="dropdown-menu">
                                <!-- <a  class="dropdown-item" :href="baseURL+'user/logout.html'">Log ud</a> -->
                                <a class="dropdown-item cursor-pointer" v-on:click="methodLogout">Logout</a>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item cart-item pt-1">
                        <a class="nav-link not-fication" :href="baseURL+'/cart/index.html'">
                            <img src="./../assets/images/cart-icon.png" alt="Profile">
                            <span>0</span>
                        </a>
                    </li>
                </ul>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    
                    <!-- main navbar  -->
                    <ul class="navbar-nav ml-auto my-2 my-lg-0">
                        <li :class="currentRouteName === 'Home' ? 'nav-item active' : 'nav-item'"> 
                            <a class="nav-link" :href="baseURL+'loungen'">
                            Loungen <sup class="new">Nyt</sup></a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" :href="baseURL+'loungen'">
                                Loungen</a>
                        </li> -->
                        <li class="nav-item dropdown">
                            <a class="nav-link" :href="baseURL+'user/owner.html'" >
                                Mit Sted
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :href="baseURL+'sugarshop/index.html'">
                            Sugarshop
                            </a>
                        </li> 
                        <li class="nav-item">
                            <a class="nav-link" :href="baseURL+'user/my_membership.html'">
                            Guldnøglen
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :href="baseURL+'faq.html'">
                            FAQ
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :href="baseURL+'help.html'">
                            Hjælp
                            </a>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </nav>
         <Modal modalId="membership" modalClass="center-title modal-dialog-centered modal-lg" modalTitle="Vælg din egen Guldnøgle" 
            v-bind:modalContent='{
                Plans: "test",
                title: "PRØV FØRST OM DET ER NOGET FOR DIG!",
                subtitle: "Få et 3 dages Guldpas for kun",
                price: "50, Kr.",
                action: "#"
                }'
            />
         <!-- <Modal modal='iframe' modalId="deleted-invitation" modalClass="center-title modal-dialog-centered modal-sm" modalTitle="" 
            v-bind:modalContent='{
                url: this.baseURL+"loungen/#/modals/deleted-invitation"
                }'
            /> -->
            <!-- <Modal modal='iframe' modalId="deleted-user" modalClass="center-title modal-dialog-centered modal-sm" modalTitle="" 
            v-bind:modalContent='{
                url: this.baseURL+"loungen/#/modals/deleted-user"
                }'
            /> -->
            <Modal modalId="deleted-invitation" modalClass="center-title modal-dialog-centered modal-sm" modalTitle="" 
            v-bind:modalContent='{
                title: "Det ser ud til, at denne invitation er blevet slettet. Prøv at oprette din egen invitation",
            }'
            />
            <Modal modalId="deleted-user" modalClass="center-title modal-dialog-centered modal-sm" modalTitle="" 
            v-bind:modalContent='{
                title: "Det ser ud til, at denne profil er blevet slettet"
                }'
            />

            <Modal modalId="plan-description" modalClass="modal-dialog-centered modal-default" 
            modalTitle="Med Guldnøglen skal du ikke længere vente på, at andre skriver til dig. Guldnøglen låser alle døre op, og du kan straks tage 1. kontakt til lige præcist dét medlem du har lyst til:" 
        v-bind:modalContent='{
          point1: "Dit eget sted hér på Sugardating.dk®, bliver også lidt mere lækkert og privat. Med Guldnøglen låser du dine billeder inde i dit eget hemmelige billedeskab. Ingen kan herefter se billederne. Udover dig, og den specielle person, som du vælger at dele en kode med. Koden indstiller du selv. Ligesom du kan ændre koden lige når du vil, og lige så mange gange du vil. Det er privat leg !",
          point2: "Gider du ikke skriverier frem og tilbage, så brug din Guldnøgle til et frækt forspring. Se hvem der har sat dig på deres Favorit liste, og tag først kontakt til dem.",
          point3: "Eller drop helt skriveriet. Leg kun VIP, og invitér med Guldnøglen direkte på privat date !"
        }' modalButtonLabel="Få din egen Guldnøgle og et frækt forspring !" :modalButton="baseURL+'user/my_membership.html#plans'" />

    </div>
</template>

<script>
import axios from 'axios';
import EventBus from './../bus';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import Modal from './../components/Modal';
// import CryptoJS from 'crypto-js';
    
export default{
    components: {
        vueCustomScrollbar,
        Modal
    },
    props:['logedinUser1'],
    data(){
        return{
            user: [],
            notificationList: [],
            userMembership: 1,
            loading: true,
            loadingNotification: true,
            loadData: '1',
            notificationNew: 0,
            pageCount: '',
            baseURL: process.env.VUE_APP_BASE_URL,
            isAddClass: false,
            accessToken: '',
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
                reachY : 'end'
            }
        }
    },
    computed: {
        // this method is created for add acive class in menu 
        currentRouteName() {
            return this.$route.name;
        },
    },
    created() {
        this.interval = setInterval(() => this.checkToken(), 2000);
    },
   methods:{
       checkToken: function(){
           const token = this.$session.get('token');
            if(!token){
                this.sessionLogout('Session logout')
            }
       },
        // this method is created for update notification count
        UpdateCount: function(){
            let formData = new FormData();
            
            formData.append('userId', this.accessToken);

            axios.post('updateNotificationCount', formData).then(response => {
                const data = response.data;
                console.log('read notifications', data)
            });
        },

        // this method is created for get notification count
        loadNotificationCount: function(item){
            let formData = new FormData();
            formData.append('userId', this.accessToken);
            formData.append('pageNo', item);
            
             axios.post('getNotificationList', formData).then(response => {
                const data = response.data;
                this.notificationNew = data.notification_counts;
                });
        },

        // this method is created for load notifications
        loadNotifications: function(item){
            let formData = new FormData();
            formData.append('userId', this.accessToken);
            formData.append('pageNo', item);
            
            axios.post('getNotificationList', formData).then(response => {
                const data = response.data;
                this.notificationNew = data.notification_counts;
                this.pageCount = data.page_counts;
                this.notificationList =  this.notificationList.concat(data.result);           
                    this.loadingNotification = false;
                }, (error) => {
                    console.log('error: ',error)
            });
            
        },

        // this method is created for open notification drower
        addClass: function() {
            if(this.isAddClass === false){
                this.isAddClass = true;
                document.body.classList.add('active-drover')
            }
            var count = document.querySelector('.notification span')
            count.remove()
            this.loadNotifications(this.loadData);
            
            this.UpdateCount();
        },
        
        // this method is created for close notification drower
        removeClass: function() {
            if(this.isAddClass === true){
                this.isAddClass = false;
                document.body.classList.remove('active-drover')
            }
        },

        // this method is created for read all notofications
        readAllNotification: function(){

            let readAllNotification = new FormData();
            readAllNotification.append('userId', this.accessToken);

            axios.post('updateAllReadNotification', readAllNotification).then(response => {
                const data = response.data;
            
                if(data.message === 'Success'){
                    const div = document.querySelectorAll(".notification-item");
                    [].forEach.call(div, function(el) {
                        el.classList.remove("latest");
                    });
                }
                }, (error) => {
                   console.log('error: ',error)
            });

            var container = document.querySelector('.notification-header a');
            container.classList.add('disabled')

        },
        
        // this method is created for load notification on scroll down
        reachedEnd() {
            var i = this.loadData;
           
            if(i < this.pageCount){
                 this.loadData = ++i;
                 if(this.loadData != '0'){
                    this.loadNotifications(this.loadData);
                 }
            }
        },

        // this method is created for read notification
        readNotification: function(user_id, item_user_id, dating_id, type, deleted, item_id, user_deleted){
            if (deleted != '0' && user_deleted != 0){
                if(type === 2){
                    this.$router.push({ name: 'Invitation-details', query: { id: dating_id, type: type } })
                }else{
                    this.$router.push({ name: 'Invitation-details', query: { id: dating_id,datingId: item_user_id, type: type } })
                }
                window.location.reload()
                if(this.isAddClass === true){
                    this.isAddClass = false;
                    document.body.classList.remove('active-drover')
                }
            }else{
                
                let readNotification = new FormData();
                readNotification.append('userId', user_id);
                readNotification.append('datingId',  dating_id);
                
                axios.post('updateReadNotification', readNotification).then(response => {
                const data = response.data;

                    if(data.message === 'Success'){
                        const div = document.querySelector("#notification-"+item_id);
                        div.classList.remove("latest");
                    }

                   }, (error) => {
                    console.log('error: ',error)
                });
            }
        },

        // this method is created for user logout
        methodLogout: function(){
           
            let formData = new FormData();   
            formData.append('user_id', this.accessToken);
            
             axios.post('postLogout', formData).then(response => {
                const data = response.data;
                    this.$session.destroy();
                    this.$cookies.remove("LoginToken");
                    this.$cookies.set('LogoutToken', data.LogoutToken);
                    this.$router.push({ name: 'Login' });
                    
                }, (error) => {
                    console.log('error: ',error)
            });
        },

        // this method is created for get user type 
        getType:function(key){
            if(key === 'VIP'){
              var type = 1;
            }else{
                type = 2;
            }
            return type;  
        },

        // this method is created for destroy session when user is loggedin on anpther device
        sessionLogout: function(message){
            if(message === "Session logout"){
                this.$session.destroy();
                this.$session.remove('token');
                this.$cookies.remove("LoginToken");
                 this.$router.push({ name: 'Login' });
            } 
        },         
    },
    beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
        
        let getUserDetailAPI = 'getUserDetail?userId='+this.accessToken; 
        this.loadNotificationCount(this.loadData);
        axios.get(getUserDetailAPI).then(response => (
            this.sessionLogout(response.data.message),
            this.user = response.data.result,
            this.userMembership = this.user.member,
            this.logedinUser = this.user.id,
            EventBus.$emit('logedinUser', this.logedinUser),
            EventBus.$emit('userMembership', this.userMembership)
        )).finally(() => (this.loading = false)); 
    }    
}
</script>
