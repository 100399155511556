<template>
  <footer id="footer" ref="footer" class="footer ">
      <div>
          <div class="footer-top text-center">
            <div class="container">
                <div class="widget"> <img alt="img" src="./../assets/images/hearticon-silver.png">
                <p class="color-light-yellow share-love">Share Your Love</p>
                <div class="social">
                    <ul class="social-share">
                    <!-- <li><a href="https://www.instagram.com/sugardating.dk" target="_blank"><i class="fa fa-instagram"></i></a></li> -->
                    <li><a href="https://twitter.com/sugardating_dk/media" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <!-- <li><a href="https://www.facebook.com/sugardating.dk" target="_blank"><i class="fa fa-facebook"></i></a></li> -->
                    <li><a href="https://www.youtube.com/channel/UCDPNIb5j9eV9OVjYJK0Pssw" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="wrap">    
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <ul class="navbar-nav">              
                                <li class="nav-item"><a :href="baseURL+'loungen'"> Loungen</a></li>
                                <li class="nav-item"><a :href="baseURL+'user/owner.html'">Mit Sted</a></li>
                                <li class="nav-item"><a :href="baseURL+'sugarshop/index.html'">Sugarshop</a></li>
                                <li class="nav-item"><a :href="baseURL+'faq.html'">FAQ</a></li>
                                <li class="nav-item"><a :href="baseURL+'help.html'">Hjælp</a></li>
                                <li class="nav-item"><a :href="baseURL+'sikkerhed.html'">Sikkerhed</a></li>
                                <li class="nav-item"><a :href="baseURL+'medlemsbetingelser.html'">Vilkår og handelsbetingelser</a></li>
                                <li class="nav-item"><a :href="baseURL+'medlemskab.html'">Guldnøglen</a></li>
                                <li class="nav-item"><a :href="baseURL+'kontakt.html'">Kontakt</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-sm-12 pt-1">
                            <a href="#" onclick="window.open('https://www.sitelock.com/verify.php?site=sugardating.dk','SiteLock','width=600,height=600,left=160,top=170');">
                                <img class="img-fluid mt-1" alt="SiteLock" title="SiteLock" src="./../assets/images/sitelock.png">
                            </a>
                        </div>
                        <div class="col-sm-12 text-center text-light mt-2">
                            <div>Copyright © 2022. All Rights Reserved. </div>
                            <div class="text-gold pt-2">Sugardating.dk® vises bedst med Mozilla Firefox, Google Chrome og Apple's Safari </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </footer>
</template>

<script>
export default {
data(){
    return{
        baseURL: process.env.VUE_APP_BASE_URL
    }
}
}
</script>
