<template>
  <div :class="'page '+ $route.name">
    <!-- test: {{this.$loader}} -->
    <div v-if="!onLine" class="text-center p-5 text-light text-middle" id="app">Can't connect to Internet?<br>Check your Internet connection and reload page.</div>
    <div v-else>
      <div v-if="$route.meta.template === 1 || $route.meta.template === 3" id="app">
        <div v-if="isLoading">
          <Nav :logedinUser1="logedinUser"/>
          <div class="text-light" v-if="show">Notified</div>
          <router-view></router-view>
          <div id="stop-scroller"></div>
          <Footer v-if="$route.meta.template != 3" />
        </div>
        <div v-else class="loader"></div>
      </div>
      <div v-else class="landing-page">
        <router-view></router-view>
      </div>
      <div class="CookieInfo" id="CookieInfo" v-if="popupCookies">
        <div class="cookie-content cookies-inner">
          <div class="container new_con">
            <div class="row">
              <div class="col-lg-10 col-md-12 text-center text-lg-left">
                <p>Alle danske hjemmesider&nbsp;er omfattet af cookie reglerne. Det er Erhvervsstyrelsen som fører tilsyn med cookiereglerne.&nbsp;Formålet&nbsp;er at beskytte brugernes privatliv og tillid til hjemmesiden. Sugardating.dk® er omfattet af cookie reglerne, og bruger&nbsp;cookies til&nbsp;optimering af sidens indhold, og brugernes oplevelse af siden. Når du klikker videre på siden, accepterer&nbsp;du brug af cookies.</p>
              </div>
              <div class="col-lg-2 col-md-12 text-center text-lg-right">
                <button @click="setCookie()" class="btn btn-light btn-long btn-sm btn-rounded close-cookie">Okay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

import Popper from 'popper.js';
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
import Nav from './components/Nav.vue';
import Footer from './components/Footer';

export default {
  name: 'App',
  data: () => ({ 
      show: false,
      isLoading: false,
      logedinUser: '',
      baseURL: process.env.VUE_APP_BASE_URL,
      onLine: navigator.onLine ? true : false,
      popupCookies: false
    }),
  components: {
    Nav,
    Footer
  },
  mounted(){   
    // this.$cookies.remove("vuePopupCookie");
    const popupCookie = this.$cookies.get('vuePopupCookie');
    const getCICookie = this.$cookies.get('CookieInformation_0');

    if(popupCookie || getCICookie){
      console.log(popupCookie, ' : ', getCICookie);
    }else{
      this.popupCookies = true;
    }

    document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.isLoading = true;
        }
      };
      if(navigator.onLine){
        this.onLine = true;
      }else{
        this.onLine = false;	
      }
  },
  methods:{
    setCookie(){
      var expires = new Date(2147483647 * 1000).toUTCString();
       this.$cookies.set("vuePopupCookie", '1', expires);
       this.popupCookies = false;
    }
  }
  
}
</script>

<style>
@import'~bootstrap/dist/css/bootstrap.css';
@import './assets/styles/style.css';

</style>
