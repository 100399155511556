import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import moment from 'moment';
import 'bootstrap';
import VTooltip from 'v-tooltip'
import VueCountdownTimer from 'vuejs-countdown-timer';
import Toasted from 'vue-toasted';
import VueSession from 'vue-session';
import VueResource from 'vue-resource';
import axios from 'axios';

Vue.use(VueResource);
var options = {
  persist: true
}

Vue.use(VueSession, options)
Vue.use(VueCountdownTimer);
Vue.use(VTooltip)
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Toasted, {
  duration: 2000
});

Vue.prototype.$loader = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

router.beforeEach((to, from, next) => {
  const autoLoginToken =  router.app.$cookies.get("AutoLoginToken");
  if(!autoLoginToken){
    const token = router.app.$session.get('token');
    if(to.name !== 'Login'){
      if(token){
            // Add a request interceptor
              axios.interceptors.request.use(function (config) {
                config.headers.common['authentication'] = token;
                return config;
            }, function (error) {
              return Promise.reject(error);
            });

            // Add a response interceptor
            axios.interceptors.response.use(function (response) {
              router.app.$loader = false;
              return response;
            }, function (error) {
              return Promise.reject(error);
            });
            next();
      }else{
        next({ name: 'Login'})
      }
    }else{
      
      if(token){
      
        var action = to.query.action;
        
        if(action === 'logout'){
          router.app.$session.destroy();
          router.app.$session.remove('token');
          router.app.$cookies.remove("LoginToken");
          window.location.reload();
          if(to.name !== 'Login'){
            next({ name: 'Login'})
            }
        }else{
          next({ name: 'Home'})
        }
        
      }else{
        next()
      }
    }
  }else{
    router.app.$session.start()
    router.app.$session.set('token', JSON.stringify(autoLoginToken))
    next()
  }
})

// Date Filter 
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY, HH:mm')
  }
});

Vue.filter('customDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
});

Vue.filter('formatTimer', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
});

// Add page title 
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title =  to.meta.title;
    });
});

new Vue({
  router,
  render: h => h(App),
  components: { App }
}).$mount('#app')
