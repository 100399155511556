<template> 
    <div class="start-post" :id="postIds">
        <div class="post-header">
            <div class="row align-items-center">
                <div class="col-sm-5">
                    <div class="d-flex align-items-center">
                        <a class="img-wrap profile-sm" >
                            <img src="./../assets/images/admin_profile_image.jpg" alt="Profile" class="profile-sm">
                        </a>
                        <div class="admin-name pl-1">
                            <h5 class="m-0 text-white">Admin Annie</h5>
                            <p class="m-0">{{convertUTC(item.flood_created_date)}}</p>
                        </div>
                    </div>
                </div>
                <div :class="liked ? ' liked-press' : ''" class="col-sm-7 pt-1 pt-sm-0">
                    <div class="d-flex justify-content-end align-items-center text-white">
                        <div v-if="totalComment > 0" class="mr-0 mr-md-2 pr-2 flex-sm-auto">
                            <button class="btn-transparent" :ref="'viewCmt_'+item.id" v-on:click="viewComment(item.id)">
                                <span>{{totalComment}}</span> {{totalComment === 1 ? 'kommentarer' : 'kommentarer'}}
                            </button>
                        </div>
                        <div v-if="item.feedReactions.totalReactionCount >= 1" class="pl-2 mr-0 mr-md-3 pr-1 dropdown ">
                            <button :disabled='btnShow' type="button" class="btn-transparent dropdown-toggle" data-toggle="dropdown">
                                <span class="liked mr-1">
                                   <span v-for="(index, i) in reactionCount.slice(0,2)" :key="i">
                                        <img alt="img" v-if="index.id === '1' && item.feedReactions.likeReactionCount != 0" src="./../assets/images/like.png">
                                        <img alt="img" v-else-if="index.id === '2' && item.feedReactions.loveReactionCount != 0" src="./../assets/images/love.png">
                                        <img alt="img" v-else-if="index.id === '3' && item.feedReactions.smileReactionCount  != 0" src="./../assets/images/haha.png">
                                        <img alt="img" v-else-if="index.id === '4' && item.feedReactions.careReactionCount  != 0" src="./../assets/images/care.png"> 
                                        <img alt="img" v-else-if="index.id === '5' && item.feedReactions.wowReactionCount  != 0" src="./../assets/images/wow.png">
                                        <img alt="img" v-else-if="index.id === '6' && item.feedReactions.sadReactionCount  != 0" src="./../assets/images/sad.png">
                                   </span>
                                </span>
                                <span>{{item.feedReactions.totalReactionCount}}</span>
                            </button>
                            <div class="dropdown-menu liked-count pt-3 pl-3 pr-3">
                                <div class="row">
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/like.png" class="like">
                                        <span>{{item.feedReactions.likeReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2" >
                                       <img alt="img" src="./../assets/images/love.png" class="love">
                                        <span>{{item.feedReactions.loveReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/haha.png" class="haha">
                                        <span>{{item.feedReactions.smileReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/care.png" class="care"> 
                                        <span>{{item.feedReactions.careReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2" >
                                       <img alt="img" src="./../assets/images/wow.png" class="wow">
                                        <span>{{item.feedReactions.wowReactionCount}}</span>
                                    </div>
                                    <div class="col-6 mb-2">
                                       <img alt="img" src="./../assets/images/sad.png" class="sad">
                                        <span>{{item.feedReactions.sadReactionCount}}</span>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        <div :class="activeLike ? 'show active-like' : ''" class="dropdown dropup like-thumb">
                          
                            <button
                            v-long-press="300"
                            v-on:mouseover="mouseover"
                            v-on:mouseleave="mouseleave"
                            @long-press-start="onLongPressStart"
                            v-on:click="likeClick(item.reaction === 0 ? '1' : item.reaction, item.id, item.reaction, 1)"
                             type="button" class="btn-transparent dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                               
                                <img alt="img" v-if="item.reaction === 0 || item.reaction === null" src="./../assets/images/like_unselect.png">
                                <img alt="img" v-else-if="item.reaction === '1'" src="./../assets/images/like_selected.png">
                                <img alt="img" v-else-if="item.reaction === '2'" src="./../assets/images/love.png">
                                <img alt="img" v-else-if="item.reaction === '3'" src="./../assets/images/care.png">
                                <img alt="img" v-else-if="item.reaction === '4'" src="./../assets/images/haha.png"> 
                                <img alt="img" v-else-if="item.reaction === '5'" src="./../assets/images/wow.png">
                                <img alt="img" v-else-if="item.reaction === '6'" src="./../assets/images/sad.png">

                            </button>
                            <div v-on:click="removeActiveLike()" class="like-wrap"></div>
                            <div  :class="activeLike ? 'show' : ''" class="dropdown-menu like-menu">
                                <span :disabled='btnShow' class="like" v-on:click="likeClick('1', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/like.png" >
                                </span>
                                <span :disabled='btnShow' class="love" v-on:click="likeClick('2', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/love.png" class="">
                                </span>
                                <span :disabled='btnShow' class="care" v-on:click="likeClick('3', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/care.png" class=""> 
                                </span>
                                <span :disabled='btnShow' class="haha" v-on:click="likeClick('4', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/haha.png" class="">
                                </span>
                                <span :disabled='btnShow' class="wow" v-on:click="likeClick('5', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/wow.png" class="">
                                </span>
                                <span :disabled='btnShow' class="sad" v-on:click="likeClick('6', item.id, item.reaction, 2)">
                                    <img alt="img" src="./../assets/images/sad.png" class="">  
                                </span>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="post-content new-signup">
            <div class="text-center">
                <div class="pb-2 pb-sm-0">
                    <h4 class="text-white mb-3"><a target="_blank" :href="item.profile_link">{{item.message}}</a>
                    </h4>
                    
                    <a target="_blank" :href="item.profile_link" :class="userMembership === '2' ? '' : 'img-blur profile-large'" class="img-wrap">
                        <vue-load-image>
                            <img alt="img" slot="image" class="profile-large" :src="item.avatar" />
                             <div slot="preloader" class="hart-loader"></div>
                            <div slot="error"></div>
                        </vue-load-image>
                    </a>
                </div>
                <div class="post-icon text-center text-light mt-2">
                    <h6>
                        <span class="profile-cat text-right">{{getLargIconTitle(item.own)}} </span>
                        <span class="profile-icon">
                            <span :class="getLargIcon(item.own)" href=#></span>
                            <span :class="getLargIcon(item.play)" href=#></span>
                        </span>
                        <span class="profile-cat text-left">{{getLargIconTitle(item.play)}}</span>
                    </h6>
                </div>
                <div v-if="item.slogan" class="text-center">
                    <div>
                        <h5 class="text-yellow mt-2">Jeg vil gerne være med herinde fordi:</h5>
                        <div class="post-description less mb-1" v-if="!readMore[postIds]">
                            {{item.slogan.length > textlength ? item.slogan.substring(0, textlength) + "..." : item.slogan}}
                            <a @click="showMore(postIds)" v-if="!readMore[postIds]" :class="item.slogan.length > textlength ? '' : 'd-none'" class="more-link">Se mere</a>
                        
                        </div>
                        <div class="post-description full mb-1" v-if="readMore[postIds]">
                            {{item.slogan}}
                            <a @click="showLess(postIds)" v-if="readMore[postIds]" :class="item.slogan.length > textlength ? '' : 'd-none'" class="more-link">Se mindre</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="post-footer">
            <div class="row align-items-center">
                <div class="col-sm-6 pb-3 pb-sm-0">
                    <div class="comment-field">
                        <input type="text" :name="'comment_'+item.id" v-model.trim="comment" v-on:keyup="pressEnter" placeholder="Skriv en kommentar...">
                        <button :disabled='btnShow' v-on:click="submit(item.id)">
                            <img src="./../assets/images/send.png" alt="Send">
                        </button>
                        <!--v-if="userMembership === '2'" <button  v-else @click="showModal = true, comment = ''">
                            <img src="./../assets/images/send.png" alt="Send">
                        </button> -->
                         
                    </div>
                </div>
                <div class="col-sm-6 text-right">
                    <div class="random-comment pl-4 pr-4">
                        
                        <VueSlickCarousel :arrows="true" :dots="false" :variableWidth="false" :infinite="false" :autoplay="false" :slidesToShow="2" :slidesToScroll="1">
                            <div class="comment-item">
                                <button class="custom_message" :disabled='btnShow' v-on:click="addComment(item.id, 'Velkommen !')">Velkommen !</button>
                            </div>
                            <div class="comment-item">
                                <button class="custom_message" :disabled='btnShow' v-on:click="addComment(item.id, 'Wauw !')">Wauw !</button>
                            </div>
                            <div class="comment-item">
                                <button class="custom_message" :disabled='btnShow' v-on:click="addComment(item.id, 'Hej !')">Hej !</button>
                            </div>
                            <template #prevArrow="arrowOption">
                                <div class="custom-arrow post-msg-next">
                                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                                    <img src="./../assets/images/arrow.png" class="next" alt="prev">
                                </div>
                            </template>
                            <template #nextArrow="arrowOption">
                                <div class="custom-arrow post-msg-next">
                                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                                    <img src="./../assets/images/arrow.png" class="next" alt="next">
                                </div>
                            </template>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
            <div :class="addCmt ? 'new-comment' : ''" class="post-comment" :id="'post_comment_'+item.id" v-if="commentList != null && commentList.length >= 1">
            <div v-for="(cmt, i) in commentList" :key="i" :class="deletedCmt[cmt.id] ? 'deleted' : ''" class="comment-row d-flex mb-3" :id="'cmt_'+cmt.id">
                <div class="user-img">
                    <a :href="cmt.profile_link" target="_blank" v-if="cmt.name !== 'Admin'" :class="userMembership === '2' ? '' : 'img-blur'" class="profile-sm img-wrap">
                        <img :src="cmt.avatar" alt="Profile" class="profile-sm">
                    </a>
                    <img src="./../assets/images/admin_profile_image.jpg" v-else alt="Profile" class="profile-sm">
                </div>
                <div class="user-post">
                    <span v-if="page === 'myFeed'">
                        <button :disabled='btnShow' v-on:click="deleteComment(i, cmt.id)" class="btn-transparent delete-comment">
                        <img alt="img" src="./../assets/images/delete.png">
                        </button>
                    </span>
                    <span v-else>
                        <button v-if="logedinUser === item.userId" :disabled='btnShow' v-on:click="deleteComment(i, cmt.id)" class="btn-transparent delete-comment">
                        <img alt="img" src="./../assets/images/delete.png">
                        </button>
                        <button v-else-if="cmt.userId === logedinUser" :disabled='btnShow' v-on:click="deleteComment(i, cmt.id)" class="btn-transparent delete-comment">
                            <img alt="img" src="./../assets/images/delete.png">
                        </button>
                    </span>
                    
                    <div class="clear-fix">
                        <h6 v-if="cmt.name !== 'Admin'" class="text-white mb-1">
                            <a :href="cmt.profile_link" target="_blank">{{cmt.name}}</a>
                        </h6>
                        <h6 v-else class="text-white mb-1">
                            Admin Annie
                        </h6>
                        <p class="mb-0">{{cmt.comment}}</p>
                        
                    </div>
                </div>
            </div>
            <div v-if="commentList != null && loading && commentList.length >= 1" class="text-center">
                <div slot="preloader" class="hart-loader"></div>
            </div>
            <div class="View-cmt text-center" v-if="singleCMT && is_visible === 1 && totalComment >= 2">
                <button :disabled='btnShow' class="btn-outline btn-outline-rounded" v-if="commentList.length < totalComment && totalComment > 1 && commentPage >= 1" v-on:click="getComment(item.id)">Se flere kommentarer</button>
                <button :disabled='btnShow' class="btn-outline btn-outline-rounded"  v-if="!singleCMT && commentList != null && commentList.length <= totalComment" :ref="'busstop_'+item.id" v-on:click="hideComment(item.id, postIds)">Luk kommentarer</button>
            </div>
            <div class="View-cmt text-center" v-else-if="!singleCMT && is_visible === 1 && totalComment > 2">
                <button :disabled='btnShow' class="btn-outline btn-outline-rounded" v-if="commentList.length < totalComment && totalComment > 2 && commentPage >= 1" v-on:click="getComment(item.id)">Se flere kommentarer</button>
                <button :disabled='btnShow' class="btn-outline btn-outline-rounded"  v-if=" !singleCMT && commentList != null && commentList.length <= totalComment" :ref="'busstop_'+item.id" v-on:click="hideComment(item.id, postIds)">Luk kommentarer</button>
            </div>
        </div>

    <!-- Modal -->
    <div class="flood-popup show" v-if="showModal" tabindex="-1" aria-hidden="true">
        <div class="modal-shadow" @click="showModal = false"></div>
        <div class="modal-small modal-dialog modal-dialog-centered">
            <div class="modal-content dark-popup">
                <div class="modal-content-inner">
                    <div class="modal-header blank-header">
                        <button type="button" class="close" @click="showModal = false">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-center mb-0 font-weight-400">Strø lidt sukker og tilføj kommentar ! 
                            <br><a class="" :href="baseURL+'user/my_membership?planid=1'" target="_blank"><u>Hent din Guldnøgle hér</u></a>
                        </p>
                    </div>
                    <div class="modal-footer">
                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="flood-popup show" v-if="showDeleteModal" :id="'deleteConfirm'+item.id" tabindex="-1" aria-hidden="true">
        <div class="modal-shadow" @click="showDeleteModal = false"></div>
        <div class="modal-small modal-dialog modal-dialog-centered">
            <div class="modal-content dark-popup">
                <div class="modal-content-inner">
                    <div class="modal-header blank-header">
                        <h4 class="modal-title text-center">Ønsker du at slette denne kommentar ?</h4>
                        <button type="button" class="close" @click="showDeleteModal = false">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-footer pt-4">
                    <button type="button" @click="confirmDeleteComment(deleteIndex, deleteCommentId,item.id)" class="btn btn-primary btn-sm btn-long">Ja</button>
                        <button type="button" class="btn btn-dark btn-sm btn-long" @click="showDeleteModal = false" data-dismiss="modal">Nej</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import LongPress from 'vue-directive-long-press';
import VueLoadImage from 'vue-load-image';
import moment from 'moment';
import axios from 'axios';

export default {
    components: {
        'vue-load-image': VueLoadImage,
        VueSlickCarousel 
    },
    directives: {
        'long-press': LongPress
    },
    props: ['page', 'item', 'logedinUser', 'userMembership', 'postIds'],
    data: () => ({
        baseURL: process.env.VUE_APP_BASE_URL,
        readMore: {},
        textlength: 70,
        reactionCount:[],
        activeLike: false,
        btnShow: false,
        comment:'',
        loadPage: 1,
        commentList: [],
        totalComment: 0,
        commentPage: 1,
        singleCMT: true,
        loading: false,
        showModal: false,
        addCmt: false,
        deletedCmt: {},
        showDeleteModal: false,
        deleteCommentId: '',
        deleteIndex: '',
        liked:false,
        is_visible: 1,
        accessToken: ''
    }),
    beforeMount(){
        this.accessToken = JSON.parse(this.$session.get('token'));
    },
    mounted() {  
        this.commentList = this.item.commentsList.data;
        this.totalComment = this.item.commentsList.count;
        this.commentPage = this.item.commentsList.page;
        this.is_visible = this.item.commentsList.is_visible;
        this.getMax();
        // this.getComment(this.item.id)
    },
    methods: {
        // this method is created for get large category icon
        getLargIcon: function(id){
            if(id==1){
               var largeIcon='large_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='large_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='large_icon-sugar-dad';
            }else if(id==4){
                largeIcon='large_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='large_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

        // this method is created for get category title
        getLargIconTitle: function(id){
            if(id==1){
               var largeIcontitle='Sugar Baby (M)'; 
            }else if(id == 2){
                largeIcontitle='Sugar Baby (F)';              
            }else if(id==3){
                largeIcontitle='Sugar Dad';
            }else if(id==4){
                largeIcontitle='Cougar';              
            }else if(id==5){
                largeIcontitle='ToyBoy';              
            }
            return largeIcontitle;  
        },

        // this method is created for get small category icon
        getSmallIcon: function(id){
            if(id==1){
               var largeIcon='small_icon-sugar-baby-male'; 
            }else if(id == 2){
                largeIcon='small_icon-sugar-baby-female';              
            }else if(id==3){
                largeIcon='small_icon-sugar-dad';
            }else if(id==4){
                largeIcon='small_icon-sugar-mom';              
            }else if(id==5){
                largeIcon='small_icon-toy-boys-mand';              
            }
            return largeIcon;  
        },

        // this method is created for convert date on UTC format
        convertUTC: function(time){
            let local = moment.utc(time).local().format('DD-MM-YYYY hh:mm');
            return local;
        },

        // this method is created for view comment (csroll to comment section)
        viewComment: function(id){
            // this.singleCMT = false;
            const listElm = document.querySelector('.page');
            const topcmt = document.getElementById('post_comment_'+id).offsetTop
            listElm.scrollTo(0 , topcmt  - 100)
        },

        // this method is created for add comment using press enter 
        pressEnter: function(e) {
            if(this.comment.length > '0' && this.comment != ' '){
                if (e.keyCode === 13) {
                    this.submit(this.item.id)
                    // if(this.userMembership === '2')
                        
                    // else{
                    //     this.comment = "";
                    //     e.target.blur(); 
                    //     this.showModal = true;
                    // }
                } 
            }
        },

        // this method is created for open reaction on hoher icon
        mouseover: function(){
            setTimeout(() => this.activeLike = true, 500);
        },  
        
        // this method is created for clode reaction box on mouse leave
        mouseleave: function(){
             setTimeout(() => this.activeLike = false, 2000);
        },

        // this method is created for see less comment 
        hideComment:function(id , itemId){
            const height = document.getElementById(itemId).clientHeight
            this.loadPage = 1
            this.singleCMT = true;
            this.commentList = this.commentList.slice(0, 2);
            const top = this.$refs['busstop_'+id].offsetTop;
            const listElm = document.querySelector('.page');
            listElm.scrollTo(0 , top - height)
        },

        // this method is created for open confirmation popup for delete comment
        deleteComment: function(index, commentId){
            this.showDeleteModal = true;
            this.deleteIndex = index;
            this.deleteCommentId = commentId;
        },

        // this method is created for delete comment
        confirmDeleteComment: function(index, commentId, postId){
            console.log('commentId : ', commentId, 'index : ',index)
            if(this.btnShow === false){
                this.btnShow = true;
                
                this.showDeleteModal = false; 
                this.$set(this.deletedCmt, commentId, true);
                 const div = document.querySelector('#cmt_'+commentId);
                setTimeout(() => { div.classList.add("d-none") }, 750)
                setTimeout(() => {
                let formData = new FormData();
                
                formData.append('commentId', commentId);
                
                axios.post('removeComments', formData).then(response => {
                const data = response.data;
                         console.log(data)
                        this.totalComment = this.totalComment - 1;
                        this.commentList.splice(index, 1)
                        this.btnShow = false;

                        const cmtDiv = document.querySelector('.comment-row');
                        cmtDiv.classList.remove("d-none")

                        if(this.commentList.length == 0 && this.totalComment >= 1){
                            this.loading = true;
                            this.getComment(postId)
                        }


                  }, (error) => {
                    console.log('error: ',error);
                });
                }, 750)
            }
        },

        // this method is created for add comment using pre build message
        addComment : function(id, comment){
            if(this.btnShow === false){
            this.btnShow = true;
                let formData = new FormData();
                
                formData.append('userId', this.accessToken);
                formData.append('postId', id);
                formData.append('comment', comment);
                
                axios.post('addComments', formData).then(response => {
                const data = response.data;
                        const result = data.result;
                        
                        this.totalComment = this.totalComment + 1;
                        if(!this.commentList){
                            this.commentList = [];
                            this.commentList.push(
                                {'id': result.id,'postId': result.postId,'profile_link': result.profile_link, 'userId': result.userId, 'comment': result.comment, 'createdAt': result.createdAt, 'avatar': result.avatar, 'name': result.name },
                            );
                        }else{
                            this.commentList = this.commentList.concat(result);
                            if(this.commentList.length > 1){
                                this.commentList =  this.commentList.sort(function(a, b) {return b.id - a.id })
                            }
                            this.addCmt = true
                            setTimeout(() => this.addCmt = false, 1000);
                        }

                        this.btnShow = false;
                        
                    }, (error) => {
                    console.log('error: ',error);
                });
            }
        }, 

        // this method is created for add comment using press submit button
        submit : function(id){
           if(this.btnShow === false){
            
            if(this.comment.length > '0' && this.comment != ' '){
                this.btnShow = true;
                let formData = new FormData();
                
                formData.append('userId', this.accessToken);
                formData.append('postId', id);
                formData.append('comment', this.comment);
                
                axios.post('addComments', formData).then(response => {
                const data = response.data;
                        const result = data.result;

                        this.comment = "";
                        this.totalComment = this.totalComment + 1;
                        
                         if(!this.commentList){
                            this.commentList = [];
                            this.commentList.push(
                                {'id': result.id,'postId': result.postId,'profile_link': result.profile_link, 'userId': result.userId, 'comment': result.comment, 'createdAt': result.createdAt, 'avatar': result.avatar, 'name': result.name },
                            );
                        }else{
                            this.commentList = this.commentList.concat(result);
                            if(this.commentList.length > 1){
                                this.commentList =  this.commentList.sort(function(a, b) {return b.id - a.id })
                            }
                            this.addCmt = true
                            setTimeout(() => this.addCmt = false, 1000);
                        }

                        this.btnShow = false;
                       
                     }, (error) => {
                    console.log('error: ',error);
                });
            }
           }
        },

        // this method is created for get comment list 
        getComment : function(id){
            this.loading = true
            if(this.loadPage === 1){
                this.singleCMT = true;
            }

            let formData = new FormData();
            
            formData.append('postId', id);
            formData.append('userId', this.accessToken);
            formData.append('pageNo', this.loadPage);
           
            axios.post('getCommentsList', formData).then(response => {
                const data = response.data;

                    const result = data.result;
                        this.btnShow = false;
                        this.loading = false
                        this.commentPage = result.page;
                        this.totalComment = result.count;

                      
                        this.singleCMT = false;
                        
                        if(this.loadPage === 1){
                            if(result){
                                this.commentList = [];   
                                this.commentList = result.data;
                            }
                        }else{
                            this.commentList = this.commentList.concat(result.data);
                        }
                         this.loadPage = this.loadPage + 1
                 }, (error) => {
                    console.log('error: ',error);
            });
        }, 

        // this method is created for show more text
        showMore(id) {
            this.$set(this.readMore, id, true);
        },

        // this method is created for show less text
        showLess(id) {
            this.$set(this.readMore, id, false);
        },

        // this method is created for active max reaction icon
        getMax:function () {
            this.reactionCount = [];
            this.reactionCount.push(
                {'id': '1','count': this.item.feedReactions.likeReactionCount},
                {'id': '2','count': this.item.feedReactions.loveReactionCount},
                {'id': '3','count': this.item.feedReactions.smileReactionCount},
                {'id': '4','count': this.item.feedReactions.careReactionCount},
                {'id': '5','count': this.item.feedReactions.wowReactionCount},
                {'id': '6','count': this.item.feedReactions.sadReactionCount}
            );
            this.reactionCount =  this.reactionCount.sort(function(a, b) {return b.count - a.count })
        },

        // this method is created for open reaction box
        onLongPressStart () {
        // triggers after 300ms of mousedown
            
            if(this.activeLike === true){
                this.activeLike = false;
            }else{
                this.activeLike = true;
            }
        },

        // this method is created for like post
        likeClick: function(type, id, userReaction, key){
            if(this.btnShow === false){
                this.btnShow = true;
                if(userReaction === 0){
                    var lastReactionType = "";
                }else{
                    lastReactionType = userReaction;
                }
                if(type === userReaction){
                    var action = 'REMOVE';
                    this.liked = false;
                }else{
                    action = 'ADD';
                    this.liked = true;
                    this.$set(this.item, 'reaction', type)
                }

                if(key === 2){
                    this.activeLike = false
                }
                let formData = new FormData();
                
                formData.append('userId', this.accessToken);
                formData.append('postId', id);
                formData.append('reactionType', type);
                formData.append('action', action);
                formData.append('lastReactionType', lastReactionType);
                
                axios.post('addAndRemoveFeedReactions', formData).then(response => {
                const data = response.data;
                        const result = data.result;
                        this.$set(this.item.feedReactions, 'totalReactionCount', result.totalReactionCount)
                        this.$set(this.item, 'reaction', result.userReaction)
                        this.$set(this.item.feedReactions, 'likeReactionCount', result.likeReactionCount)
                        this.$set(this.item.feedReactions, 'loveReactionCount', result.loveReactionCount)
                        this.$set(this.item.feedReactions, 'smileReactionCount', result.smileReactionCount)
                        this.$set(this.item.feedReactions, 'careReactionCount', result.careReactionCount)
                        this.$set(this.item.feedReactions, 'wowReactionCount', result.wowReactionCount)
                        this.$set(this.item.feedReactions, 'sadReactionCount', result.sadReactionCount)
                        this.$set(this.item.feedReactions, 'id', result.id)
                        this.$set(this.item.feedReactions, 'postId', result.postId)
                        this.btnShow = false;
                        this.getMax()
                        this.liked = false;

                     }, (error) => {
                    console.log('error: ',error);
                });
            }
        },

        // this method is created for unlike post
        removeActiveLike:function(){
            this.activeLike = false;
        }
    }
}
</script>

<style>

</style>